<template>
    <div class="d-flex mx-auto align-items-center">
        <div class="d-flex align-items-center">
            <div class="h2 btn btn-lg bg-primary text-white">
                N° <span v-if="selectedReservation">{{ selectedReservation.position }}</span>
            </div>
            <div class="mx-2 text-primary" @click="createReservation" title="Nouvelle réservation" role="button">
                <i class="fa-solid fa-circle-plus bg-white p-2 rounded-circle" style="font-size: 2rem;"></i>
            </div>
        </div>
        <div style="max-width: 65vw; overflow-x: auto;" class="reservations-container">
            <div class="d-flex justify-content-center px-3">
                <div
                    v-for="reservation in reservations"
                    :key="reservation.id"
                    class="rounded-pill reservation-circle mx-2"
                    :class="{
                        'border-primary text-primary': selectedReservation && reservation.id === selectedReservation.id && reservation.status !== 'Annule',
                        'bg-danger border-danger text-white': reservation.status === 'Annule',
                    }"
                    @click="selectReservation(reservation)"
                >
                    <span>{{ reservation.position || 'N/A' }}</span>
                </div>
            </div>
        </div>
        <div class="d-flex ml-auto">
            <div
                class="mx-2 text-danger"
                v-if="isTreatable()"
                @click="cancelReservation"
                title="Annuler la réservation"
                role="button"
            >
                <i class="fas fa-minus-circle bg-white p-2 rounded-circle" style="font-size: 2rem;"></i>
            </div>
            <div class="mx-2 text-primary" @click="refreshReservations" title="Rafraîchir" role="button">
                <i class="fa-solid fa-rotate bg-white p-2 rounded-circle" style="font-size: 2rem;"></i>
            </div>
        </div>
    </div>
</template>

<script>
import { 
    readJsonFromFile, 
    saveJsonToFile, 
    updateReservationInJson, 
    addProductToReservation, 
    removeProductFromReservation, 
    initializeJsonFile,
    addReservationToJson, // Ajouté pour gérer l'ajout de réservations
    updateProductInReservation // Ajouté si nécessaire
} from '@/views/calculs/logUtils';
export default {
    name: "ReservationTopbar",
    data() {
        return {
            reservations: [], // Liste des réservations disponibles
            selectedReservation: null, // Réservation sélectionnée
            isLoading: false, // Indicateur de chargement
            baseUrl: this.$store.state.api_epharma_ventes, // URL de l'API
           
        };
    },
    async mounted() {
        
       
        // Initialiser le fichier JSON si nécessaire
        await initializeJsonFile();

        // Charger les réservations au montage
        this.refreshReservations();

       
    },
    methods: {
        async refreshReservations() {
            this.isLoading = true;

            try {
                // Effectuer une requête GET pour récupérer les réservations du jour
                const response = await this.axios.get(`${this.baseUrl}/api/reservations/jour`);
                
                // Log complet de la réponse
                console.log("Réponse complète de l'API :", response);

                if (response.status === 200) {
                    const reservationsData = response.data.data; // Accès correct au tableau des réservations
                    console.log("Données de réservations reçues :", reservationsData);

                    if (Array.isArray(reservationsData)) {
                        this.reservations = reservationsData;
                    } else if (typeof reservationsData === 'object') {
                        this.reservations = [reservationsData];
                        console.warn("La réponse de l'API n'était pas un tableau. Les données ont été enveloppées dans un tableau.");
                    } else {
                        this.reservations = [];
                        console.warn("La réponse de l'API ne contient pas de données de réservations valides.");
                    }

                    // Charger les réservations modifiées depuis le LocalStorage
                    const jsonData = await readJsonFromFile();
                    if (jsonData && jsonData.reservations) {
                        const modifiedReservations = jsonData.reservations.filter(res => res.created_at !== res.updated_at);
                        console.log("Réservations modifiées depuis LocalStorage :", modifiedReservations);

                        // Remplacer les réservations de l'API par les réservations modifiées du LocalStorage
                        modifiedReservations.forEach(modRes => {
                            const index = this.reservations.findIndex(res => res.id === modRes.id);
                            if (index !== -1) {
                                this.$set(this.reservations, index, modRes);
                            } else {
                                // Si la réservation modifiée n'existe pas dans l'API, l'ajouter
                                this.reservations.push(modRes);
                            }
                        });
                        console.log("Réservations après fusion :", this.reservations);
                    } else {
                        console.warn("Aucune réservation modifiée trouvée dans LocalStorage.");
                    }

                    if (this.reservations.length > 0) {
                        // Sélectionner la première réservation par défaut si aucune n'est sélectionnée
                        if (!this.selectedReservation) {
                            this.selectedReservation = this.reservations[0];
                        }
                        // Incrémenter la clé pour forcer la mise à jour si nécessaire
                        this.key++;
                    } else {
                        console.warn("Aucune réservation du jour trouvée.");
                        this.selectedReservation = null;
                    }
                } else {
                    console.warn("La requête a échoué :", response.statusText);
                    this.reservations = [];
                }
            } catch (error) {
                console.error("Erreur lors de la récupération des réservations du jour :", error);
                this.$toast.add({
                    severity: 'error',
                    summary: 'Problème de connexion',
                    detail: "Une erreur s'est produite lors de la connexion au serveur !",
                    life: 5000
                });
            } finally {
                this.isLoading = false;
            }
        },


        async createReservation() {
            this.isLoading = true;

            try {
                // 📝 Étape 1 : Préparation des données de création de réservation (sans l'id)
                const form = {
                    created_by: this.$store.getters.user.fullname,
                    client_id: 1,
                    libelle: "COMPTANT",
                    status: "Brouillon",
                    client: "COMPTANT",
                    caisse: "Default",
                    montant_taxe: 0,
                    total: 0,
                    montant: 0,
                };

                // 🌐 Étape 2 : Envoi de la demande de création de réservation à l'API
                const response = await this.axios.post(`${this.baseUrl}/api/reservations`, form);

                // 📋 Log de la réponse de l'API
                console.log("Réponse de l'API lors de la création de la réservation :", response.data);

                if (response.data && response.data.success) {
                    const createdReservation = response.data.data;
                    console.log("Réservation créée via API :", createdReservation); // Log de la réservation créée

                    // Vérifier si 'position' est présent
                    if (createdReservation.position == null) {
                        createdReservation.position = this.reservations.length + 1;
                        console.warn("Position non définie dans la réponse de l'API. Position assignée côté client :", createdReservation.position);
                    }

                    // ➕ Étape 3 : Ajouter la réservation créée au localStorage
                    await addReservationToJson(createdReservation);

                    // 📂 Rafraîchir la liste des réservations depuis LocalStorage
                    await this.refreshReservations();

                    // ✅ Rafraîchir la sélection avec la nouvelle réservation
                    await this.selectReservation(createdReservation);

                    // 🎉 Notification de succès
                    this.$toast.add({
                        severity: "success",
                        summary: "Succès",
                        detail: "Réservation créée avec succès.",
                        life: 5000
                    });
                } else {
                    // Gestion des erreurs si la réponse n'est pas succès
                    console.warn("Création de la réservation échouée :", response.data);
                    this.$toast.add({
                        severity: "error",
                        summary: "Erreur",
                        detail: "La création de la réservation a échoué.",
                        life: 5000
                    });
                }
            } catch (error) {
                // ❌ Gestion des erreurs
                console.error("Erreur lors de la création de la réservation :", error);
                this.$toast.add({
                    severity: "error",
                    summary: "Erreur",
                    detail: "Une erreur s'est produite lors de la création de la réservation.",
                    life: 5000
                });
            } finally {
                this.isLoading = false;
            }
        },



        async selectReservation(reservation) {
            this.selectedReservation = reservation; // ✅ Met à jour la réservation sélectionnée
            console.log("Réservation sélectionnée :", this.selectedReservation);
            this.$emit('reservationSelected', reservation);

            
        },
        cancelReservation() {
            if (this.selectedReservation) {
                this.isLoading = true;
                this.selectedReservation.status = 'Annule';
                this.axios.post(`${this.baseUrl}/api/reservations/${this.selectedReservation.id}/update`, this.selectedReservation)
                    .then(response => {
                        if (response.data.success) {
                            this.refreshReservations();
                            this.$toast.add({
                                severity: 'success',
                                summary: 'Succès',
                                detail: response.data.message,
                                life: 3000
                            });
                        } else {
                            response.data.errors.forEach(error => {
                                this.$toast.add({
                                    severity: 'warn',
                                    summary: 'Erreur',
                                    detail: error,
                                    life: 7000
                                });
                            });
                        }
                        this.isLoading = false;
                    })
                    .catch(() => {
                        this.isLoading = false;
                        this.$toast.add({
                            severity: 'error',
                            summary: 'Problème de connexion',
                            detail: 'Impossible d\'annuler la réservation.',
                            life: 5000
                        });
                    });
            }
        },
        isTreatable() {
            return this.selectedReservation &&
                this.selectedReservation.status !== 'Termine' &&
                this.selectedReservation.status !== 'Annule' &&
                this.selectedReservation.status !== 'Devis';
        },



    }
};

</script>
<style scoped>
.reservations-container {
    max-height: 100px; /* Limitez la hauteur si nécessaire */
    white-space: nowrap; /* Force les éléments à rester sur une seule ligne */
    display: flex;
    align-items: center;
    overflow-x: auto; /* Active le défilement horizontal si nécessaire */
    scrollbar-width: thin; /* Réduit la largeur de la barre de défilement */
    scrollbar-color: #ccc #f5f5f5; /* Style de la barre de défilement */
}
.reservation-circle {
    min-width: 40px;
    width: 40px;
    height: 40px;
    border: 2px solid lightgray;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.3s ease;
    font-weight: bold;
}

.reservation-circle:hover {
    border-color: gray;
}

</style>