import { createStore } from 'vuex'
import router from '../router'
import axios from 'axios';

interface User {
  id: number,
  photo: string,
  name: string,
  fullname: string,
  email: string,
  role: string,
  must_change_password: string,
  adresse: string,
  boite_postale: string,
  ville: string,
  date_naissance: Date,
  sexe: string,
  poste: string,
  token: string,
  telephone: string,
  is_enabled: boolean,
  is_admin: boolean,
  habilitations: any | null,
}

interface Pharmacie {
  logo: string,
  nom_pharmacie: string,
  adresse: string,
  capital: string,
  statut_juridique: string,
  rccm: string,
  nif: string,
  telephone1: string,
  telephone2: string,
  email: string,
  site_web: string,
  devise: string,
  bp: string,
  slogan: string
}

interface State {
  appIsLoading: boolean,
  authenticated: boolean,
  caisseAuthenticated: boolean,
  caisseConnected: string,
  // api_url: string;
  api_epharma_comptes: string;
  api_epharma_stock: string;
  api_epharma_ventes: string;
  api_epharma_update: string;
  tva: number;
  css: number;
  habilitations: any | null,
  user: User | null; // Utilisateur unique ou null
  pharmacie: Pharmacie | null; // Pharmacie unique ou null
}

export default createStore({
  state: {
    appIsLoading: false,
    authenticated: false,
    api_epharma_update: '',
    caisseAuthenticated: false,
    caisseConnected: '',
    // api_url: 'http://192.168.1.80:8000',

    api_epharma_comptes: 'https://api-comptes.srv557357.hstgr.cloud',
    api_epharma_stock: 'https://api-stock.srv557357.hstgr.cloud',
    api_epharma_ventes: 'https://api-ventes.srv557357.hstgr.cloud',

    // api_epharma_comptes:'http://api-comptes.epharma',
    // api_epharma_stock: 'http://api-stock.epharma',
    // api_epharma_ventes: 'http://api-ventes.epharma',

    // api_epharma_comptes: 'http://localhost:8000',
    // api_epharma_stock: 'http://localhost:8001',
    // api_epharma_ventes: 'http://localhost:8002',

    tva: 0.18,
    css: 0.01,
    habilitations: null,
    user: null, // Initialiser à null car pas d'utilisateur par défaut
    pharmacie: null,
    timezone: 'Africa/Libreville',
  } as State,
  getters: {
    appIsLoading: (state: State) => state.appIsLoading,
    authenticated: (state: State) => state.authenticated,
    user: (state: State) => state.user,
    pharmacie: (state: State) => state.pharmacie,
    habilitations: (state: State) => state.habilitations,
    caisseAuthenticated: (state: State) => state.caisseAuthenticated,
    caisseConnected: (state: State) => state.caisseConnected,
    apiComptes: (state: State) => state.api_epharma_comptes,
    apiStock: (state: State) => state.api_epharma_stock,
    apiVentes: (state: State) => state.api_epharma_ventes,
    apiUpdate: (state: State) => state.api_epharma_update,
    // apiUrl: (state: State) => state.api_url,
    tva: (state: State) => state.tva,
    css: (state: State) => state.css,
  },
  mutations: {
    setUserHabilitations(state: State, value: User){
      state.habilitations = value.habilitations
    },
    setAuthenticated(state: State, value: User) {
      state.appIsLoading = true
      state.authenticated = true
      state.user = value
      if (value.is_admin) {
        state.habilitations = [
          { menu: 'ADMIN', submenus: ['utilisateur', 'parametre'] },
          { menu: 'STOCK', submenus: ['produits', 'commande', 'base', 'fournisseur', 'rayon', 'mouvement'] },
          { menu: 'GESTION', submenus: ['billetage', 'stat', 'inventaire', 'journee', 'garde'] },
          { menu: 'VENTE', submenus: ['client', 'caisse', 'reservation', 'devis', 'vente', 'configuration'] },
          { menu: 'PRIVILEGES', submenus: ['inventaire_master', 'inventaire_mobile'] },
        ]
      } else state.habilitations = value.habilitations
      window.localStorage.setItem('epharma_asdecode_session', JSON.stringify(value))
      state.appIsLoading = false
    },
    setAuthenticatedCaisse(state: State, value: string) {
      state.caisseAuthenticated = true
      state.caisseConnected = value
    },
    clearAuthenticatedCaisse(state: State) {
      state.caisseAuthenticated = false
      state.caisseConnected = ''
    },
    setIsLoading(state: State, value: boolean) {
      state.appIsLoading = value
    },
    clearUserData(state: State) {
      state.appIsLoading = true
      state.authenticated = false
      state.user = null
      window.localStorage.removeItem('epharma_asdecode_session')
      state.appIsLoading = false
      router.push({ name: 'login' })
    },
    setUserProfileDatas(state: State, value: User) {
      state.appIsLoading = true
      state.user = value
      window.localStorage.setItem('epharma_asdecode_session', JSON.stringify(value))
      state.appIsLoading = false
    },
    setPharmacieDatas(state: State, value: Pharmacie) {
      state.pharmacie = value
    },
  },
  actions: {
    async fetchCompanyInfo({ commit }) {
      try {
        const response = await axios.get('/api/pharmacie-info');
        commit('setPharmacieDatas', response.data);
      } catch (error) {
        console.error('Erreur lors de la récupération des informations:', error);
      }
    },
  },
  modules: {}
})
