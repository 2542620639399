<template>
  <div class="container mt-4">
    
    <div class="card-wrapper">
      <div class="card" style="width: 100%;" @mouseover="showButton = true" @mouseleave="showButton = false">
        <div class="card-body">
          <ChartJS type="line" :data="chartData" :options="chartOptions" class="h-50rem" />
          <button
            v-if="showButton"
            class="btn btn-primary export-btn"
            @click="openStatExportModal"
          >
            Exporter <i class="fas fa-file-export"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ChartJS } from "chart.js";
import Statistiques from "./ExportStatistique.vue";

export default {
  name: "ChiffreAffaireMensuelLineChart",
  components: { Statistiques },
  props: {
    filters: {
      type: Object,
      required: true,
    },
    period: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      baseUrl: this.$store.state.api_epharma_ventes,
      months: [
        "Janvier", "Février", "Mars", "Avril", "Mai", "Juin",
        "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre",
      ],
      chartData: {
        labels: [],
        datasets: [
          {
            label: `Chiffre d'affaires (${this.period}) (FCFA)`,
            backgroundColor: "rgba(75, 192, 192, 0.2)",
            borderColor: "rgba(75, 192, 192, 1)",
            borderWidth: 2,
            fill: true,
            data: [],
          },
        ],
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: {
            title: {
              display: true,
              text: "Mois",
            },
          },
          y: {
            beginAtZero: true,
            title: {
              display: true,
              text: "Montant (FCFA)",
            },
          },
        },
        plugins: {
          legend: {
            display: true,
            position: "top",
          },
          tooltip: {
            mode: "index",
            intersect: false,
          },
        },
      },
      showButton: false,
      statData: [],
      columns: ["Période", "Total Chiffre d'Affaire (FCFA)"],
      TitleStat: "Chiffre d'Affaire Mensuel",
    };
  },
  computed: {
    dataTable() {
      return {
        columns: this.columns,
        rows: this.statData,
        title: this.TitleStat,
      };
    },
  },
  methods: {
    openStatExportModal() {
      const uniqueID = `12`;
      this.$dialog.open(Statistiques, {
        props: {
          header: "Statistiques - Chiffre d'Affaire Mensuel",
          style: {
            width: "70vw",
          },
          modal: true,
        },
        data: this.dataTable,
        uniqueID: uniqueID, // Passer l'ID unique au composant enfant
      });
    },
    fetchData() {
      let url = `${this.baseUrl}/api/stat/chiffre-affaire`;

      if (this.filters.debut && this.filters.fin) {
        url += `?period_from=${this.filters.debut}&period_to=${this.filters.fin}`;
      }

      this.axios
        .get(url)
        .then((response) => {
          const data = response.data;

          // Créer un tableau associatif des données reçues avec la clé "mois"
          const dataMap = new Map(data.map((item) => [item.mois, item.total]));

          // Compléter les données manquantes avec les mois et les valeurs à 0 si nécessaire
          const monthlyData = this.months.map((month) => ({
            label: month,
            total: dataMap.get(month) || 0,
          }));

          this.chartData.labels = monthlyData.map((item) => item.label);
          this.chartData.datasets[0].data = monthlyData.map((item) => item.total);

          this.statData = monthlyData.map((item) => ({
            Période: item.label,
            "Total Chiffre d'Affaire (FCFA)": item.total,
          }));
        })
        .catch((error) => {
          console.error("Erreur lors de la récupération des données :", error);
        });
    },
  },
  mounted() {
    this.fetchData();
  },
};
</script>

<style scoped>
.card-wrapper {
  position: relative;
}

.export-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.card:hover .export-btn {
  opacity: 1;
}

.export-btn i {
  margin-left: 5px;
}
</style>
