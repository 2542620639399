import { saveAs } from 'file-saver';

const FILE_NAME = '/logs-Reservation.json'; // Assurez-vous que le fichier est dans le dossier public

// 📤 Lecture des données depuis LocalStorage
export async function readJsonFromFile(): Promise<any> {
    try {
        const data = localStorage.getItem('logs-Reservation');
        if (!data) {
            console.warn('LocalStorage vide. Initialisation avec des données vides.');
            return { reservations: [], reservation_produits: [] };
        }
        return JSON.parse(data);
    } catch (error) {
        console.error('Erreur lors de la lecture depuis LocalStorage :', error);
        return { reservations: [], reservation_produits: [] };
    }
}


// 📥 Sauvegarde des données dans LocalStorage
export async function saveJsonToFile(data: any): Promise<void> {
    try {
        localStorage.setItem('logs-Reservation', JSON.stringify(data));
        console.log('Données sauvegardées dans LocalStorage.');
    } catch (error) {
        console.error('Erreur lors de la sauvegarde dans LocalStorage :', error);
    }
}


// 📥 Mise à jour d'une réservation
export async function updateReservationInJson(reservationId: number, updatedReservation: any): Promise<void> {
    const jsonData = await readJsonFromFile();
    if (!jsonData) return;

    const reservationIndex = jsonData.reservations.findIndex((res: any) => res.id === reservationId);
    if (reservationIndex !== -1) {
        jsonData.reservations[reservationIndex] = { ...jsonData.reservations[reservationIndex], ...updatedReservation };
        await saveJsonToFile(jsonData);
        console.log(`Réservation ID ${reservationId} mise à jour avec succès.`);
    } else {
        console.warn(`Réservation ID ${reservationId} non trouvée.`);
    }
}

// 📥 Ajout d'un produit à une réservation
export async function addProductToReservation(reservationId: number, product: any): Promise<void> {
    const jsonData = await readJsonFromFile();

    if (!jsonData) {
        console.error('Échec de l\'ajout du produit : Fichier JSON vide ou introuvable.');
        return;
    }

    jsonData.reservation_produits.push({ reservation_id: reservationId, ...product });
    await saveJsonToFile(jsonData);
    console.log(`Produit ajouté à la réservation ID ${reservationId}.`);
}

// 🗑️ Suppression d'un produit d'une réservation
export async function removeProductFromReservation(reservationId: number, productId: number): Promise<void> {
    const jsonData = await readJsonFromFile();
    if (!jsonData) return;

    jsonData.reservation_produits = jsonData.reservation_produits.filter(
        (prod: any) => !(prod.reservation_id === reservationId && prod.produit_id === productId)
    );

    await saveJsonToFile(jsonData);
    console.log(`Produit ID ${productId} supprimé de la réservation ID ${reservationId}.`);
}

// 🛠️ Initialisation du fichier JSON si nécessaire
export async function initializeJsonFile(): Promise<void> {
    const jsonData = await readJsonFromFile();

    if (!jsonData || !jsonData.reservations) {
        console.log('Initialisation du fichier JSON avec des données vides.');
        await saveJsonToFile({ reservations: [], reservation_produits: [] });
    }
}
// 📥 Ajout d'une réservation
// 📥 Ajout d'une réservation
export async function addReservationToJson(newReservation: any): Promise<void> {
    try {
        const jsonData = await readJsonFromFile();
        if (!jsonData) {
            // Initialiser les données si elles n'existent pas
            await saveJsonToFile({ reservations: [], reservation_produits: [] });
            console.warn('LocalStorage initialisé avec des données vides.');
        }

        // Vérifier si 'position' est présent, sinon l'assigner
        if (newReservation.position == null) {
            newReservation.position = jsonData.reservations.length + 1;
            console.warn(`Position non définie pour la réservation ID ${newReservation.id}. Position assignée : ${newReservation.position}`);
        }

        // Ajouter la nouvelle réservation
        jsonData.reservations.push(newReservation);
        await saveJsonToFile(jsonData);
        console.log(`Réservation ID ${newReservation.id} ajoutée avec succès au LocalStorage.`);
    } catch (error) {
        console.error('Erreur lors de l\'ajout de la réservation dans LocalStorage :', error);
    }
}


