<template>
  <div>
    <h3 class="text-center">Produits achetés</h3>
    <div class="card" @mouseover="showButton = true" @mouseleave="showButton = false">
      <div class="card-body">
        <canvas ref="produitsAchetesChart"></canvas>
      </div>
      <button
        v-if="showButton"
        class="btn btn-primary export-btn"
        @click="openStatExportModal"
      >
        Exporter <i class="fas fa-file-export"></i>
      </button>
    </div>
  </div>
</template>

<script>
import Chart from "chart.js/auto";
import Statistiques from "./ExportStatistique.vue"; // Composant modal pour les statistiques

export default {
  name: "StatistiqueAChat",
  components: { Statistiques },
  props: {
    filters: {
      type: Object,
      required: true,
    },
    period: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      chart: null,
      produitLibelles: [],
      totalQuantites: [],
      baseUrl: this.$store.state.api_epharma_stock,
      showButton: false,
      statData: [],
      columns: ["Produit", "Quantité totale achetée"],
      TitleStat : "Produits achetés",
    };
  },
  computed: {
    dataTable() {
      return {
        columns: this.columns,
        rows: this.statData,
        title: this.TitleStat,
      };
    },
  },
  watch: {
    filters: {
      deep: true,
      handler() {
        this.fetchData(); // Recharger les données si les filtres changent
      },
    },
    period: {
      immediate: true,
      handler() {
        this.fetchData(); // Recharger les données si la période change
      },
    },
  },
  methods: {
    async fetchData() {
      try {
        let url = `${this.baseUrl}/api/statistiques/produits-achetes`;

        if (this.filters.debut && this.filters.fin) {
          url += `?period_from=${this.filters.debut}&period_to=${this.filters.fin}`;
        } else if (this.filters.week) {
          const [year, week] = this.filters.week.split("-W");
          const firstDayOfWeek = new Date(year, 0, 1 + (week - 1) * 7);
          const lastDayOfWeek = new Date(firstDayOfWeek);
          lastDayOfWeek.setDate(firstDayOfWeek.getDate() + 6);
          url += `?period_from=${firstDayOfWeek.toISOString().slice(0, 10)}&period_to=${lastDayOfWeek.toISOString().slice(0, 10)}`;
        } else if (this.filters.month) {
          const [year, month] = this.filters.month.split("-");
          const firstDayOfMonth = new Date(year, month - 1, 1);
          const lastDayOfMonth = new Date(year, month, 0);
          url += `?period_from=${firstDayOfMonth.toISOString().slice(0, 10)}&period_to=${lastDayOfMonth.toISOString().slice(0, 10)}`;
        }

        const response = await fetch(url);
        if (!response.ok) throw new Error("Erreur lors du chargement des données.");

        const data = await response.json();

        this.produitLibelles = data.map((item) => item.produit_libelle);
        this.totalQuantites = data.map((item) => item.total_qte);
        this.statData = data.map((item) => ({
          Produit: item.produit_libelle,
          "Quantité totale achetée": item.total_qte,
        }));

        this.$nextTick(() => this.renderChart());
      } catch (error) {
        console.error("Erreur :", error);
      }
    },
    renderChart() {
      const canvas = this.$refs.produitsAchetesChart;

      if (!canvas) {
        console.error("Le canvas n'est pas encore monté.");
        return;
      }

      const ctx = canvas.getContext("2d");
      if (this.chart) this.chart.destroy();

      this.chart = new Chart(ctx, {
        type: "bar",
        data: {
          labels: this.produitLibelles,
          datasets: [
            {
              label: "Quantités totales achetées",
              data: this.totalQuantites,
              backgroundColor: "rgba(75, 192, 192, 0.2)",
              borderColor: "rgba(75, 192, 192, 1)",
              borderWidth: 1,
            },
          ],
        },
        options: {
          responsive: true,
          plugins: {
            legend: { display: false },
            tooltip: {
              callbacks: {
                label: (context) => {
                  const label = context.label || "";
                  const value = context.raw || 0;
                  return `${label}: ${value}`;
                },
              },
            },
          },
        },
      });
    },
    openStatExportModal() {
      this.$dialog.open(Statistiques, {
        props: {
          header: "Statistiques - Produits achetés",
          style: {
            width: "70vw",
          },
          modal: true,
        },
        data: this.dataTable,
      });
    },
  },
  mounted() {
    this.fetchData();
  },
};
</script>

<style scoped>
canvas {
  max-width: 100%;
  height: auto;
}

.export-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.card:hover .export-btn {
  opacity: 1;
}

.export-btn i {
  margin-left: 5px;
}
</style>
