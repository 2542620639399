<template>
    <div class="container mt-4">
      <h3 class="text-center">Produits du Dernier Inventaire</h3>
      <div class="card" @mouseover="showButton = true" @mouseleave="showButton = false">
        <div class="card-body">
          <canvas ref="barChart"></canvas>
        </div>
        <button
          v-if="showButton"
          class="btn btn-primary export-btn"
          @click="openStatExportModal"
        >
          Exporter <i class="fas fa-file-export"></i>
        </button>
      </div>
    </div>
  </template>
  
  <script>
  import Chart from "chart.js/auto";
  import Statistiques from "./ExportStatistique.vue"; // Composant pour le modal des statistiques
  
  export default {
    name: "BarChart",
    components: { Statistiques },
    props: {
      filters: {
        type: Object,
        required: true,
      },
      period: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        produits: [], // Liste des produits
        labels: [], // Labels pour le graphique
        data: [], // Données pour le graphique
        chart: null, // Instance Chart.js
        baseUrl: this.$store.state.api_epharma_stock,
        showButton: false, // Contrôle de l'affichage du bouton d'exportation
        statData: [], // Données pour le tableau des statistiques
        columns: ["Produit", "Quantité Réelle"], // Colonnes pour le tableau
        TitleStat : "Nombre de clients servis par Utilisateur",
      };
    },
    computed: {
      dataTable() {
        return {
          columns: this.columns,
          rows: this.statData,
          title: this.TitleStat,
        };
      },
    },
    watch: {
      filters: {
        deep: true,
        handler() {
          this.fetchData(); // Recharger les données si les filtres changent
        },
      },
      period: {
        immediate: true,
        handler() {
          this.fetchData(); // Recharger les données si la période change
        },
      },
    },
    methods: {
      async fetchData() {
        try {
          let url = `${this.baseUrl}/api/statistiques/dernier-inventaire`;
  
          if (this.filters.debut && this.filters.fin) {
            url += `?period_from=${this.filters.debut}&period_to=${this.filters.fin}`;
          } else if (this.filters.week) {
            const [year, week] = this.filters.week.split("-W");
            const firstDayOfWeek = new Date(year, 0, 1 + (week - 1) * 7);
            const lastDayOfWeek = new Date(firstDayOfWeek);
            lastDayOfWeek.setDate(firstDayOfWeek.getDate() + 6);
            url += `?period_from=${firstDayOfWeek.toISOString().slice(0, 10)}&period_to=${lastDayOfWeek.toISOString().slice(0, 10)}`;
          } else if (this.filters.month) {
            const [year, month] = this.filters.month.split("-");
            const firstDayOfMonth = new Date(year, month - 1, 1);
            const lastDayOfMonth = new Date(year, month, 0);
            url += `?period_from=${firstDayOfMonth.toISOString().slice(0, 10)}&period_to=${lastDayOfMonth.toISOString().slice(0, 10)}`;
          }
  
          const response = await this.axios.get(url);
  
          if (response.data.data && response.data.data.length > 0) {
            this.produits = response.data.data;
            this.labels = this.produits.map((produit) => produit.produit_libelle);
            this.data = this.produits.map((produit) => produit.qte_reelle);
            this.statData = this.produits.map((produit) => ({
              Produit: produit.produit_libelle,
              "Quantité Réelle": produit.qte_reelle,
            }));
          } else {
            this.labels = ["Aucun produit"];
            this.data = [0];
            this.statData = [{ Produit: "Aucun produit", "Quantité Réelle": 0 }];
          }
  
          this.$nextTick(() => {
            this.renderChart();
          });
        } catch (error) {
          console.error("Erreur lors de la récupération des données :", error);
          this.labels = ["Aucun produit"];
          this.data = [0];
          this.statData = [{ Produit: "Aucun produit", "Quantité Réelle": 0 }];
          this.$nextTick(() => {
            this.renderChart();
          });
        }
      },
      renderChart() {
        const canvas = this.$refs.barChart;
  
        if (!canvas) {
          console.error("Le canvas n'est pas encore monté.");
          return;
        }
  
        const ctx = canvas.getContext("2d");
        if (this.chart) this.chart.destroy(); // Détruire l'ancien graphique si existant
  
        this.chart = new Chart(ctx, {
          type: "bar",
          data: {
            labels: this.labels,
            datasets: [
              {
                label: "Quantité Réelle",
                data: this.data,
                backgroundColor: "rgba(75, 192, 192, 0.6)",
                borderColor: "rgba(75, 192, 192, 1)",
                borderWidth: 1,
              },
            ],
          },
          options: {
            responsive: true,
            plugins: {
              legend: {
                display: false, // Masquer la légende
              },
            },
            scales: {
              y: {
                beginAtZero: true,
              },
            },
          },
        });
      },
      openStatExportModal() {
        this.$dialog.open(Statistiques, {
          props: {
            header: "Statistiques",
            style: {
              width: "70vw",
            },
            modal: true,
          },
          data: this.dataTable,
        });
      },
    },
    async mounted() {
      await this.fetchData();
    },
  };
  </script>
  
  <style scoped>
  .container {
    max-width: 800px;
  }
  
  .export-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}
  
  .card:hover .export-btn {
    opacity: 1;
  }
  
  .export-btn i {
    margin-left: 5px;
  }
  </style>
  