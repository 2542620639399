<template>
    <EpharmaTemplate>
        <template #page_title>STATISTIQUES</template>
        <template #page_counter>
            <i class="fa-solid fa-chart-simple" style="font-size: 25px;"></i>
        </template>
        <template #content>
            <div class="container">
               
                <!-- Filtre Périodique -->
                <div class="d-flex justify-content-center my-3">
                    <div class="btn-group" role="group" aria-label="Filter Period">
                        <button 
                            type="button" 
                            class="btn" 
                            :class="{'btn-primary': TypePeriode === 'JOURNALIERE', 'btn-light': TypePeriode !== 'JOURNALIERE'}"
                            @click="changePeriod('JOURNALIERE')">
                            Journalière
                        </button>
                        <button 
                            type="button" 
                            class="btn" 
                            :class="{'btn-primary': TypePeriode === 'HEBDOMADAIRE', 'btn-light': TypePeriode !== 'HEBDOMADAIRE'}"
                            @click="changePeriod('HEBDOMADAIRE')">
                            Hebdomadaire
                        </button>
                        <button 
                            type="button" 
                            class="btn" 
                            :class="{'btn-primary': TypePeriode === 'MENSUEL', 'btn-light': TypePeriode !== 'MENSUEL'}"
                            @click="changePeriod('MENSUEL')">
                            Mensuel
                        </button>
                    </div>
                </div>
                <!-- Sélection de la période -->
                <div class="card mb-4">
                    <div class="card-body">
                        <div v-if="TypePeriode === 'JOURNALIERE'" class="row">
                            <div class="col-md-6">
                                <label for="debut">Date de début</label>
                                <input type="date" id="debut" v-model="filterForm.debut" class="form-control" />
                            </div>
                            <div class="col-md-6">
                                <label for="fin">Date de fin</label>
                                <input type="date" id="fin" v-model="filterForm.fin" class="form-control" />
                            </div>
                        </div>

                        <div v-else-if="TypePeriode === 'HEBDOMADAIRE'" class="row">
                            <div class="col-md-6">
                                <label for="week">Semaine</label>
                                <input type="week" id="week" v-model="filterForm.week" class="form-control" />
                            </div>
                        </div>

                        <div v-else-if="TypePeriode === 'MENSUEL'" class="row">
                            <div class="col-md-6">
                                <label for="month">Mois</label>
                                <input type="month" id="month" v-model="filterForm.month" class="form-control" />
                            </div>
                        </div>

                        <div class="text-center mt-3">
                            <button class="btn btn-primary" @click="applyFilters">Valider</button>
                        </div>
                    </div>
                </div>

                <div class="card">
                    <TabView>
                        <!-- Onglet Produits -->
                        <TabPanel header="Produits">
                            <h3 class="text-center my-3 ">Évolution du chiffre d'affaires</h3>
                            <div class="d-flex justify-content-center mb-3 ">
                                
                                <ChiffreAffaire :filters="currentFilters" :period="TypePeriode" :key="childKey" ></ChiffreAffaire>
                            </div>
                            <div class="d-flex mb-3 row">
                                <div class="col-md-6">
                                    <h3>Produits les plus vendus</h3>
                                    <ProduitVendus :filters="currentFilters" :period="TypePeriode" :key="childKey"></ProduitVendus>
                                </div>
                                <div class="col-md-6">
                                    <h3>Produits les moins vendus</h3>
                                    <ProduitMoinsVendus :filters="currentFilters" :period="TypePeriode" :key="childKey"></ProduitMoinsVendus>
                                </div>
                            </div>
                            <div class="d-flex mb-3">
                                <div class="col-md-6">
                                    <QteVenteProduit :filters="currentFilters" :period="TypePeriode" :key="childKey"></QteVenteProduit>
                                </div>
                                <div class="col-md-6">
                                    <QteVentesAnnulees :filters="currentFilters" :period="TypePeriode" :key="childKey"></QteVentesAnnulees>
                                </div>
                            </div>
                        </TabPanel>

                        <!-- Onglet Inventaire -->
                        <TabPanel header="Inventaire">
                            <div class="mt-3">
                                <QteDernierInventaire :filters="currentFilters" :period="TypePeriode" :key="childKey"></QteDernierInventaire>
                            </div>
                            <div class="mt-3">
                                <DiffDernierInventaire :filters="currentFilters" :period="TypePeriode" :key="childKey"></DiffDernierInventaire>
                            </div>
                            <div class="mt-3">
                                <StatistiqueAchat :filters="currentFilters" :period="TypePeriode" :key="childKey"></StatistiqueAchat>
                            </div>
                            <div class="mt-3">
                                <MargeAchat :filters="currentFilters" :period="TypePeriode" :key="childKey"></MargeAchat>
                            </div>
                        </TabPanel>

                        <!-- Onglet Utilisateurs -->
                        <TabPanel header="Utilisateurs">
                        <div class="row mt-3">
                            <!-- Chiffre d'affaire utilisateur -->
                            <div class="col-md-4">
                            <ChiffreAffaireUtilisateur
                                :filters="currentFilters"
                                :period="TypePeriode"
                                :key="childKey"
                            />
                            </div>

                            <!-- Panier moyen utilisateur -->
                            <div class="col-md-8">
                            <StatPanierMoyenUser
                                :filters="currentFilters"
                                :period="TypePeriode"
                                :key="childKey"
                            />
                            </div>
                        </div>

                        <!-- Nombre de clients servis -->
                        <div class="mt-4">
                            <StatClientServis
                            :filters="currentFilters"
                            :period="TypePeriode"
                            :key="childKey"
                            />
                        </div>
                        </TabPanel>

                    </TabView>
                </div>
            </div>
        </template>
    </EpharmaTemplate>

</template>

<script>

import ChiffreAffaire from './ChiffreAffaire.vue';
import ProduitVendus from './ProduitVendus.vue';
import ProduitMoinsVendus from './ProduitMoinsVendus.vue';
import QteDernierInventaire from './QteDernierInventaire.vue';
import DiffDernierInventaire from './DiffDernierInventaire.vue';
import QteVenteProduit from './QteVenteProduit.vue';
import QteVentesAnnulees from './QteVentesAnnulees.vue';
import StatistiqueAchat from './StatistiqueAchat.vue';
import MargeAchat from './MargeAchat.vue'
import ChiffreAffaireUtilisateur from './ChiffreAffaireUtilisateur.vue';
import StatPanierMoyenUser from './StatPanierMoyenUser.vue';
import StatClientServis from './StatClientServis.vue';

import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';




export default {
    components: {
        ChiffreAffaire, ProduitVendus, ProduitMoinsVendus, QteDernierInventaire, DiffDernierInventaire, QteVenteProduit,
        QteVentesAnnulees, StatistiqueAchat, MargeAchat, ChiffreAffaireUtilisateur, StatPanierMoyenUser, StatClientServis
    },
    data() {
        const today = new Date();
        const currentMonth = today.toISOString().slice(0, 7); // YYYY-MM
        const currentDate = today.toISOString().slice(0, 10); // YYYY-MM-DD
        return {
            chartData: null,
            chartOptions: null,
            TypePeriode: 'JOURNALIERE', // Période par défaut
            filterForm: {
                debut: currentDate,
                fin: currentDate,
                week: `${today.getFullYear()}-W${String(Math.ceil(today.getDate() / 7)).padStart(2, '0')}`,
                month: currentMonth,
            },
            currentFilters: {}, // Copie des filtres validés
            childKey: 0, // Clé dynamique pour forcer le rendu du composant enfant
        };
    },
    mounted() {
        this.chartData = this.setChartData();
        this.chartOptions = this.setChartOptions();
    },
    methods: {
        checkUrlFormat(link) {
            if (link !== undefined && link.includes('?')) link = link + '&'
            else link = link + '?'
            return link
        },
        changePeriod(period) {
            this.TypePeriode = period;
        },
        applyFilters() {
            // Valider les filtres et mettre à jour les données pour le composant enfant
            this.currentFilters = { ...this.filterForm };
            this.childKey++; // Mettre à jour la clé pour forcer le rendu
        },
        periodFilter() {
            let newUrl = this.defaultUrl

            if (this.filterForm.debut) {
                newUrl = this.checkUrlFormat(newUrl) + 'from_period_debut=' + this.filterForm.debut
            }

            if (this.filterForm.fin) {
                newUrl = this.checkUrlFormat(newUrl) + 'from_period_fin=' + this.filterForm.fin
            }

            this.url = newUrl
            this.key++
        },
        setChartData() {
            const documentStyle = getComputedStyle(document.documentElement);

            return {
                labels: ['TVA', 'CSS', 'Achat', 'Benefice'],
                datasets: [
                    {
                        label: "Premier chiffre d'affaire",
                        data: [540, 325, 702],
                        hoverBackgroundColor: [documentStyle.getPropertyValue('--cyan-400'), documentStyle.getPropertyValue('--orange-400'), documentStyle.getPropertyValue('--gray-400')],
                        backgroundColor: documentStyle.getPropertyValue('--cyan-500'),
                        borderColor: documentStyle.getPropertyValue('--cyan-500'),
                        data: [65, 59, 80, 81, 56, 55, 40]
                    },
                    {
                        label: "Second chifre d'affaire",
                        backgroundColor: documentStyle.getPropertyValue('--gray-500'),
                        borderColor: documentStyle.getPropertyValue('--gray-500'),
                        data: [28, 48, 40, 19, 86, 27, 90]
                    }
                ]
            };
        },
        setChartOptions() {
            const documentStyle = getComputedStyle(document.documentElement);
            const textColor = documentStyle.getPropertyValue('--text-color');
            const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
            const surfaceBorder = documentStyle.getPropertyValue('--surface-border');

            return {
                maintainAspectRatio: false,
                aspectRatio: 0.8,
                plugins: {
                    legend: {
                        labels: {
                            color: textColor,
                            usePointStyle: true
                        }
                    }
                },
                scales: {
                    x: {
                        ticks: {
                            color: textColorSecondary,
                            font: {
                                weight: 500
                            }
                        },
                        grid: {
                            display: false,
                            drawBorder: false
                        }
                    },
                    y: {
                        ticks: {
                            color: textColorSecondary
                        },
                        grid: {
                            color: surfaceBorder,
                            drawBorder: false
                        }
                    }
                }
            };
        }
    }
};
</script>
<style scoped>
.btn {
    border-radius: 20px;
    border: solid 1px #e4e4e4;
    padding: 10px 20px;
    margin: 0 5px;
    font-size: 14px;
    font-weight: bold;
}
.btn-primary {
    background-color: rgb(11, 80, 140);
    color: white;
}
.btn-light {
    background-color: #f8f9fa;
    color: #6c757d;
}
</style>
