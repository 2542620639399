<template>
  <h3>Quantité Produits Vendus</h3>
  <div class="card-wrapper">
    <div class="card" @mouseover="showButton = true" @mouseleave="showButton = false">
      <div class="card-body">
        <canvas id="soldProductsChart" ref="soldProductsChart"></canvas>
        <button
          v-if="showButton"
          class="btn btn-primary export-btn"
          @click="openStatExportModal"
        >
          Exporter <i class="fas fa-file-export"></i>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Chart from "chart.js/auto";
import ExportStatistique from "./ExportStatistique.vue"; // Import du composant modal

export default {
  name: "QteVenteProduit",
  components: { ExportStatistique },
  props: {
    filters: {
      type: Object,
      required: true,
    },
    period: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      chart: null,
      libelles: [],
      quantites: [],
      baseUrl: this.$store.state.api_epharma_ventes,
      titleSuffix: "par Mois",
      showButton: false,
      TitleStat : "Quantité Produits Vendus",
    };
  },
  computed: {
    dataTable() {
      return {
        columns: ["Produit", "Quantité vendue"],
        rows: this.libelles.map((libelle, index) => ({
          Produit: libelle,
          "Quantité vendue": this.quantites[index],
        })),
        title: this.TitleStat,
      };
    },
  },
  watch: {
    filters: {
      deep: true,
      handler() {
        this.fetchData();
      },
    },
    period: {
      immediate: true,
      handler(newPeriod) {
        this.fetchData();
      },
    },
  },
  methods: {
    async fetchData() {
      try {
        let url = `${this.baseUrl}/api/statistiques/sold-products`;

        if (this.filters.debut && this.filters.fin) {
          url += `?period_from=${this.filters.debut}&period_to=${this.filters.fin}`;
        } else if (this.filters.week) {
          const [year, week] = this.filters.week.split("-W");
          const firstDayOfWeek = new Date(year, 0, 1 + (week - 1) * 7);
          const lastDayOfWeek = new Date(firstDayOfWeek);
          lastDayOfWeek.setDate(firstDayOfWeek.getDate() + 6);
          url += `?period_from=${firstDayOfWeek.toISOString().slice(0, 10)}&period_to=${lastDayOfWeek.toISOString().slice(0, 10)}`;
        } else if (this.filters.month) {
          const [year, month] = this.filters.month.split("-");
          const firstDayOfMonth = new Date(year, month - 1, 1);
          const lastDayOfMonth = new Date(year, month, 0);
          url += `?period_from=${firstDayOfMonth.toISOString().slice(0, 10)}&period_to=${lastDayOfMonth.toISOString().slice(0, 10)}`;
        }

        const response = await fetch(url);
        if (!response.ok) throw new Error("Erreur lors du chargement des données.");

        const data = await response.json();
        this.libelles = data.map((item) => item.libelle);
        this.quantites = data.map((item) => item.total_qte);

        this.$nextTick(() => {
          this.renderChart();
        });
      } catch (error) {
        console.error("Erreur :", error);
      }
    },
    renderChart() {
      const canvas = this.$refs.soldProductsChart;
      if (!canvas) return;

      const ctx = canvas.getContext("2d");

      if (this.chart) this.chart.destroy();

      this.chart = new Chart(ctx, {
        type: "doughnut",
        data: {
          labels: this.libelles,
          datasets: [
            {
              data: this.quantites,
              backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56", "#4BC0C0", "#9966FF", "#FF9F40"],
              hoverOffset: 4,
            },
          ],
        },
        options: {
          responsive: true,
          plugins: {
            legend: {
              display: true,
              position: "top",
            },
            tooltip: {
              callbacks: {
                label: (context) => {
                  const label = context.label || "";
                  const value = context.raw || 0;
                  return `${label}: ${value}`;
                },
              },
            },
          },
        },
      });
    },
    openStatExportModal() {
      this.$dialog.open(ExportStatistique, {
        props: {
          header: "Statistiques",
          style: {
            width: "70vw",
          },
          modal: true,
        },
        data: this.dataTable,
      });
    },
  },
  async mounted() {
    await this.fetchData();
  },
};
</script>

<style scoped>
.card-wrapper {
  position: relative;
}

.export-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.card:hover .export-btn {
  opacity: 1;
}

canvas {
  max-width: 100%;
  height: auto;
}
</style>
