<template>
  <div class="container mt-5">
    <h3 class="text-center">Nombre de clients servis par Utilisateur</h3>
    <div class="card" @mouseover="showButton = true" @mouseleave="showButton = false">
      <div class="card-body">
        <canvas ref="ventesUtilisateurChart"></canvas>
      </div>
      <button
        v-if="showButton"
        class="btn btn-primary export-btn"
        @click="openStatExportModal"
      >
        Exporter <i class="fas fa-file-export"></i>
      </button>
    </div>
  </div>
</template>

<script>
import Chart, { Title } from "chart.js/auto";
import Statistiques from "./ExportStatistique.vue"; // Composant modal pour les statistiques

export default {
  name: "StatClientServis",
  components: { Statistiques },
  props: {
    filters: {
      type: Object,
      required: true,
    },
    period: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      chartInstance: null, // Instance du graphique
      ventesData: [], // Contiendra les données pour le graphique
      ventesLabels: [], // Contiendra les noms des utilisateurs
      baseUrl: this.$store.state.api_epharma_ventes,
      showButton: false, // Contrôle de l'affichage du bouton d'exportation
      statData: [], // Données pour le tableau
      columns: ["Utilisateur", "Nombre de clients servis"], // Colonnes du tableau
      TitleStat : "Nombre de clients servis par Utilisateur",
    };
  },
  computed: {
    dataTable() {
      return {
        columns: this.columns,
        rows: this.statData,
        title: this.TitleStat,
      };
    },
  },
  watch: {
    filters: {
      deep: true,
      handler() {
        this.fetchData(); // Recharger les données si les filtres changent
      },
    },
    period: {
      immediate: true,
      handler() {
        this.fetchData(); // Recharger les données si la période change
      },
    },
  },
  methods: {
    async fetchData() {
      try {
        let url = `${this.baseUrl}/api/statistiques/clients-utilisateur`;

        // Ajout des filtres à l'URL
        if (this.filters.debut && this.filters.fin) {
          url += `?period_from=${this.filters.debut}&period_to=${this.filters.fin}`;
        } else if (this.filters.week) {
          const [year, week] = this.filters.week.split("-W");
          const firstDayOfWeek = new Date(year, 0, 1 + (week - 1) * 7);
          const lastDayOfWeek = new Date(firstDayOfWeek);
          lastDayOfWeek.setDate(firstDayOfWeek.getDate() + 6);
          url += `?period_from=${firstDayOfWeek.toISOString().slice(0, 10)}&period_to=${lastDayOfWeek.toISOString().slice(0, 10)}`;
        } else if (this.filters.month) {
          const [year, month] = this.filters.month.split("-");
          const firstDayOfMonth = new Date(year, month - 1, 1);
          const lastDayOfMonth = new Date(year, month, 0);
          url += `?period_from=${firstDayOfMonth.toISOString().slice(0, 10)}&period_to=${lastDayOfMonth.toISOString().slice(0, 10)}`;
        }

        const response = await fetch(url);
        if (!response.ok) throw new Error("Erreur lors de la récupération des données.");

        const data = await response.json();

        // Extraire les données pour le graphique et le tableau
        this.ventesData = data.map((item) => item.nombre_ventes);
        this.ventesLabels = data.map((item) => item.user);
        this.statData = data.map((item) => ({
          Utilisateur: item.user,
          "Nombre de clients servis": item.nombre_ventes,
        }));

        this.$nextTick(() => this.renderChart());
      } catch (error) {
        console.error("Erreur lors de la récupération des données :", error);
      }
    },
    renderChart() {
      const canvas = this.$refs.ventesUtilisateurChart;

      if (!canvas) {
        console.error("Le canvas n'est pas encore monté.");
        return;
      }

      const ctx = canvas.getContext("2d");

      if (this.chartInstance) {
        this.chartInstance.destroy(); // Détruire le graphique existant avant d'en créer un nouveau
      }

      this.chartInstance = new Chart(ctx, {
        type: "bar",
        data: {
          labels: this.ventesLabels,
          datasets: [
            {
              label: "Nombre de clients servis",
              data: this.ventesData,
              backgroundColor: "rgba(75, 192, 192, 0.6)",
              borderColor: "rgba(75, 192, 192, 1)",
              borderWidth: 1,
            },
          ],
        },
        options: {
          responsive: true,
          plugins: {
            legend: {
              display: true,
              position: "top",
            },
          },
          scales: {
            y: {
              beginAtZero: true,
            },
          },
        },
      });
    },
    openStatExportModal() {
      this.$dialog.open(Statistiques, {
        props: {
          header: "Statistiques - Nombre de clients servis par Utilisateur",
          style: {
            width: "70vw",
          },
          modal: true,
        },
        data: this.dataTable,
      });
    },
  },
  mounted() {
    this.fetchData();
  },
};
</script>

<style scoped>
.container {
  max-width: 800px;
  margin: auto;
}

.export-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.card:hover .export-btn {
  opacity: 1;
}

.export-btn i {
  margin-left: 5px;
}
</style>
