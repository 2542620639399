<template>
    <EpharmaTemplate :withHeader="false">
        <template #content v-if="isReady">
            <div class="bg-primary rounded" v-if="gardeActive">
                <p class="text-center text-white">Une garde est en cours, taxe : {{
                    $moneyFormat(gardeActive.montant_taxe) }}</p>
            </div>
            <div class="container py-3">
                <TopbarReservation @reservationSelected="selectReservation" @loadFromJson="loadReservationFromJson" @loadFromDatabase="loadReservationFromDatabase" @refresh-reservations="refreshReservations"/>
                <!-- {{ this.localReservation }}
                {{ this.reservation_produits }} -->
                <div class="row d-flex justify-content-center">
                    <div class="col-md-9 h-fixed">
                        <!-- Loader -->
                        <HourGlassLoader v-if="isLoading" :isOverlay="true"></HourGlassLoader>
                        <HourGlassLoader v-else-if="isSaving" :isAutoSave="true" message="Sauvegarde en cours...">
                        </HourGlassLoader>

                        <!-- Formulaire de réservation -->
                        <div v-if="localReservation && localReservation.id">
                            <SectionVisibility :key="keyForm" libelleSection="La première étape" :isOpen="true">
                                <template #section_content>
                                    <div class="card card-body mb-3 position-relative">
                                        <!-- Overlay -->
                                        <div id="overlay" class="overlay"
                                            v-if="localReservation.status === 'Annule' || localReservation.status === 'Devis' || localReservation.status === 'Termine'">
                                        </div>
                                        <!-- Filigrane -->
                                        <div v-if="localReservation.status === 'Annule'"
                                            class="watermark border-danger px-5">
                                            Réservation annulée </div>
                                        <div v-else-if="localReservation.status === 'Termine'"
                                            class="watermark border-danger px-5">
                                            Réservation fermée </div>
                                        <div v-else-if="localReservation.status === 'Devis'"
                                            class="watermark border-danger px-5">
                                            Transformée en devis </div>

                                        <!-- Sélection de la caisse -->
                                        <div class="d-flex flex-wrap mb-3">
                                            <button v-for="caisse in caisses" :key="caisse.id"
                                                :class="{ 'border border-primary': localReservation.caisse_id === caisse.id }"
                                                class="btn btn-light mr-2 mb-2 py-3 px-4 text-dark"
                                                @click="setCaisseId(caisse)">
                                                <i class="fas fa-cash-register mr-1"></i>
                                                <span>{{ caisse.libelle }}</span>
                                            </button>
                                        </div>

                                        <!-- Formulaire principal -->
                                        <form>
                                        <div class="form-row">
                                           <!-- Client -->
                                           <div class="form-group col-md-5">
                                                <label>Client</label>
                                                <input v-if="nbProducts > 0 || reservation_produits.length > 0" type="text" class="form-control" :value="localReservation.client" disabled />
                                                <select v-else class="form-control border border-dark px-[4rem]" v-model="localReservation.client_id" @change="setClientLibelle">
                                                    <option v-for="client in clients" :key="client.id" :value="client.id">
                                                        {{ client.libelle || localReservation.client }}
                                                    </option>
                                                </select>
                                            </div>
                                            <!-- Crédit -->   
                                            <div v-if="clientSelected && parseFloat(clientSelected.current_remboursement_amount) > 0 && localReservation.status !== 'Termine'" class="form-group col-md-5">
                                                <label>Crédit</label>
                                                <input type="text" class="form-control" :class="creditClass" :value="localReservation.creditRestant" disabled />
                                            </div>

                                        </div>

                                       <!-- Assurance -->
                                       <div v-if="clientSelected && clientSelected.libelle !== 'COMPTANT' || localReservation.client_id === 1 && localReservation.client_id === null" class="form-row">
                                            <div class="form-group col-md-5">
                                                <label>Identifiant de l'Assuré (NAG)</label>
                                                <input type="text" v-model="localReservation.identifiant_assure" class="form-control border border-dark px-[4rem]" @change="autoSave()" />
                                            </div>
                                            <div class="form-group col-md-5">
                                                <label>Nom de l'Assuré</label>
                                                <input type="text" v-model="localReservation.nom_assure" class="form-control border border-dark px-[4rem]" @change="autoSave()" />
                                            </div>
                                            <div class="form-group col-md-5">
                                                <label>N° Feuille Assuré</label>
                                                <input type="text" v-model="localReservation.numero_feuille_assure" class="form-control border border-dark px-[4rem]" @change="autoSave()" />
                                            </div>
                                            <div class="form-group col-md-5">
                                                <label>Secteur</label>
                                                <select v-model="localReservation.secteur_assure" class="form-control border border-dark px-[4rem]" @change="autoSave()">
                                                    <option>Secteur public</option>
                                                    <option>Secteur privé</option>
                                                    <option>GEF</option>
                                                </select>
                                            </div>
                                        </div>
                                    </form>

                                    </div>
                                </template>
                            </SectionVisibility>

                            <SectionVisibility :key="keySelection" libelleSection="la selection des produits"
                                :isOpen="true" v-if="localReservation.status != 'Termine'">
                                <template #section_content>
                                    <div class="mb-3 position-relative">
                                        <div class="form-group">
                                            <input type="search" class="form-control form-control-lg search-form"
                                                placeholder="Tapez le mot clé à rechercher" v-model="keyWord"
                                                @input="keySelectionTable++">
                                        </div>
                                        <AsdecodeTable v-if="keyWord != ''" :key="keySelectionTable" noExport
                                            :url="$store.state.api_epharma_stock + '/api/produits?is_active=1'"
                                            :columns="columns" :newKeyWord="keyWord" table="produits">
                                            <template #header_action_btns>
                                                <button v-if="produits_selected.length > 0"
                                                    class="btn btn-light text-secondary mr-2" disabled>
                                                    {{ produits_selected.length }} produits sélectionnés
                                                </button>
                                            </template>
                                            <template #action_btns="slotProps">
                                                <span v-if="isLoadingInput[slotProps.data.id]" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                <div v-else >
                                                    <button v-if="produits_selected.includes(slotProps.data.id)" class="btn btn-danger btn-sm  m-1"
                                                        @click="toggleProduitLocal(slotProps.data, 'remove')" title="Retirer">
                                                        <i class="fa-solid fa-trash-alt"></i>
                                                    </button>
                                                    
                                                    <button v-else-if="!produits_selected.includes(slotProps.data.id) && slotProps.data.qte > 0 && parseFloat(slotProps.data.prix_de_vente) > 0"
                                                        type="button" class="btn btn-success btn-sm  m-1" @click="toggleProduitLocal(slotProps.data, 'add')"
                                                        title="Ajouter">
                                                        <i class="fa-solid fa-plus"></i>
                                                    </button>
                                                
                                                    <button type="button" class="btn btn-secondary btn-sm"
                                                        @click="commandProduit(slotProps.data.id)" title="Commander">
                                                        <i class="fa-solid fa-cart-arrow-down"></i>
                                                    </button>
                                                </div>
                                            </template>
                                            <template #libelle="slotProps">
                                                {{ slotProps.data.libelle }}
                                                <span class="text-vert ml-2" v-if="slotProps.data.tva == '1'">TVA</span>
                                                <span class="text-bleu3 ml-2"
                                                    v-if="slotProps.data.css == '1'">CSS</span>
                                            </template>
                                        </AsdecodeTable>
                                    </div>
                                </template>
                            </SectionVisibility>

                            <SectionVisibility :key="keyShow" libelleSection="les produits selectionnés" :isOpen="true">
                                <template #section_content>
                                    <div class="card">
                                        <div class="card-header p-0">
                                            <button type="button" class="btn btn-light mr-2" @click="transformToInvoice"
                                                v-if="(isTreatable() == true) && (reservation_produits.length > 0)">
                                                <i class="fa-solid fa-file-pdf"></i> Transformer en devis
                                                <span v-if="devisIsLoading" class="spinner-border spinner-border-sm"
                                                    role="status" aria-hidden="true"></span>
                                            </button>
                                        </div>
                                        <div class="card-body p-0">
                                            <DataTable :key="keyShow" :value="reservation_produits" paginator :rows="5"
                                                :rowsPerPageOptions="[5, 10, 20, 50]">
                                            <!-- Bouton suppression -->
                                            <Column field="id" header=" " v-if="isTreatable()">
                                                <template #body="slotProps">
                                                    <span v-if="isLoadingInput[slotProps.data.id]"
                                                        class="spinner-border spinner-border-sm" role="status"></span>
                                                    <button v-else type="button" class="btn btn-sm text-danger"
                                                            @click="updateLineLocal(slotProps.data, 'remove')">
                                                        <i class="fa-solid fa-trash-alt"></i>
                                                    </button>
                                                </template>
                                            </Column>

                                            <!-- Libellé -->
                                            <Column field="libelle" header="PRODUIT">
                                                <template #body="slotProps">
                                                    {{ slotProps.data.libelle }}
                                                </template>
                                            </Column>

                                            <!-- Quantité -->
                                            <Column field="qte" header="QUANTITE">
                                                <template #body="slotProps">
                                                    <input type="number"
                                                        class="form-control border border-dark px-[4rem]"
                                                        name="qte"
                                                        :value="slotProps.data.qte"
                                                        :max="parseInt(slotProps.data.produit?.qte || 1)"
                                                        min="1"
                                                        @change="updateLineLocal(slotProps.data, 'qte', $event.target.value)"
                                                        :disabled="isLoadingInput[slotProps.data.id] ||
                                                                    !isTreatable()">
                                                </template>
                                            </Column>

                                            <!-- Prix Unitaire -->
                                            <Column field="prix_de_vente" header="P.U.">
                                                <template #body="slotProps">
                                                    {{ $moneyFormat(slotProps.data.prix_de_vente) }}
                                                </template>
                                            </Column>

                                            <!-- Prise en charge -->
                                            <Column field="prise_en_charge" header="% TAUX">
                                                <template #body="slotProps">
                                                    <input type="number"
                                                        class="form-control border border-dark px-[4rem]"
                                                        name="prise_en_charge"
                                                        :value="slotProps.data.prise_en_charge || 0"
                                                        min="0"
                                                        max="100"
                                                        @change="updateLineLocal(slotProps.data, 'prise_en_charge', $event.target.value)"
                                                        :disabled="isLoadingInput[slotProps.data.id] || 
                                                                    !isTreatable()">
                                                </template>
                                            </Column>
                                        </DataTable>

                                        </div>
                                    </div>
                                </template>
                            </SectionVisibility>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="card card-body p-1 bg-primary text-white mb-2">
                            <h5>TTC CLIENT</h5>
                            <span class="text-center h4">{{ $moneyFormat(montant) }}</span>
                        </div>

                        <!-- Autres Totaux -->
                        <div class="card card-body p-1 bg-info text-white mb-2">
                            <span v-if="refreshIsLoading" class="spinner-border spinner-border-sm" role="status"></span>
                            <div v-else-if="produits_selected.length > 0 || reservation_produits.length > 0">
                                <!-- % TAUX -->
                                <div class="d-flex justify-content-between" 
                                    v-if="totalPriseEnCharge !== null && totalPriseEnCharge !== undefined">
                                <span>% TAUX: </span>
                                <span>{{ $moneyFormat(totalPriseEnCharge) }}</span>
                                </div>

                                <!-- Montant TTC -->
                                <div class="d-flex justify-content-between" 
                                    v-if="totalTTC !== null && totalTTC !== undefined">
                                <span>MT TTC: </span>
                                <span>{{ $moneyFormat(totalTTC) }}</span>
                                </div>

                                <!-- Montant HT -->
                                <div class="d-flex justify-content-between" 
                                    v-if="totalHT !== null && totalHT !== undefined">
                                <span>MT HT: </span>
                                <span>{{ $moneyFormat(totalHT) }}</span>
                                </div>

                                <!-- Montant TVA -->
                                <div class="d-flex justify-content-between" 
                                    v-if="totalTVA !== null && totalTVA !== undefined">
                                <span>MT TVA: </span>
                                <span>{{ $moneyFormat(totalTVA) }}</span>
                                </div>

                                <!-- Montant CSS -->
                                <div class="d-flex justify-content-between" 
                                    v-if="totalCSS !== null && totalCSS !== undefined">
                                <span>MT CSS: </span>
                                <span>{{ $moneyFormat(totalCSS) }}</span>
                                </div>

                                <!-- Montant Garde -->
                                <div class="d-flex justify-content-between" 
                                    v-if="totalGarde !== null && totalGarde !== undefined">
                                <span>Garde: </span>
                                <span>{{ $moneyFormat(totalGarde) }}</span>
                                </div>

                            </div>
                        </div>
                        <!-- <div class="d-flex">
                            <label >Remise:</label>
                            <input type="number" style="width: 90px;" class="form-control border border-dark px-[4rem] ml-2 mb-3"
                            name="" @input="calculRemise()" v-model="form.remise">
                        </div> -->
                        <div class="mt-3" v-if="localReservation.client_id && localReservation.switch_caisse_at">
                            <p>Réservation envoyé à la caisse {{ localReservation.caisse }}</p>
                        </div>
                        <!-- Bouton pour passer à la caisse -->
                        <div class="mt-3" v-if="localReservation.caisse_id && isTreatable() && produits_selected.length > 0">
                            <button type="button" class="btn btn-primary mx-auto mt-5 mb-1" @click="sendReservation()">
                            <i class="fa-solid fa-share mr-1"></i> Passer à la caisse
                            </button>
                        </div>

                        <BuyReservation
                            :key="keyReglement"
                            v-if="produits_selected.length > 0"
                            :reservation="localReservation"       
                            :montantTTC="montant"                 
                            :remiseValue="clientRemisePercent"
                            :reservation_produits="reservation_produits"
                            :clientSelected="clientSelected"
                           
                            @reservation-updated="handleReservationUpdated" 
                        />
                    </div>
                </div>
            </div>
        </template>
    </EpharmaTemplate>
</template>

<script>
import TopbarReservation from "./TopbarReservation.vue";
import ShowReservation from "./ShowReservation.vue";
import BuyReservation from "./BuyReservation.vue";
import { calculerProduit } from '@/views/calculs/add-produits-reservation.ts';
import {calculerTotalProduits} from '@/views/calculs/calcul-for-submit-db.ts'
import $ from 'jquery';
import { defineComponent } from 'vue';
import {readJsonFromFile, 
    saveJsonToFile, 
    updateReservationInJson, 
    addProductToReservation, 
    removeProductFromReservation, 
    initializeJsonFile } from "@/views/calculs/logUtils";
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import _ from 'lodash';


export default defineComponent({
    name: "Reservations",
    components: { TopbarReservation, ShowReservation, BuyReservation, DataTable, Column },
    data() {
        return {
            isReady: false,
            table: 'reservation_produits',
            lines: 0,
            key: 1,
            keyForm: 1,
            keySelection: 1,
            keySelectionTable: 1,
            loadedData: null,
            keyShow: 1,
            isLoading: false,
            isSaving: false,
            nb_products: 0,
            reservations: [], // Liste des réservations
            caisses: [],
            clients: [],
            reservation_produits: [], // liste des produits de la reservations
            selectedReservation: null, // la réservation sélectionnée
            globalReservation: null, // objet de la réservation sélectionnée qui va recevoir les modification
            baseUrl: this.$store.state.api_epharma_ventes,
            gardeActive: null,
            clientRemisePercent: 0,
            localReservation: {},
            clientSelected: null,
            debounceTimer: null,
            isLoadingInput: {},
            keyWord: '',
            produits_selected: [],
            devisIsLoading: false,
            isSavingJson: false, // Empêche les accès concurrents
            columns: [
                {
                    key: 'libelle',
                    label: 'NOM',
                    format: 'slot',
                    exportable: true
                },
                {
                    key: 'prix_de_vente',
                    label: 'P.U.',
                    format: 'money',
                    exportable: true
                },
                {
                    key: 'cip',
                    label: 'CIP',
                    exportable: true
                },
                {
                    key: 'qte',
                    label: 'QT. ACTU',
                    exportable: true,

                },
                {
                    key: 'rayon',
                    label: 'RAYON',
                    exportable: 'true'

                }
            ]
        };
    },
    created() {
        this.checkGardeActive()
    },
    computed: {
        creditActuel() {
            const creditInitial = parseFloat(this.clientSelected?.current_remboursement_amount || 0);
            const totalPriseEnCharge = parseFloat(this.totalPriseEnCharge || 0);
            return (creditInitial - totalPriseEnCharge).toFixed(2);
        },
        creditClass() {
            const creditActuel = parseFloat(this.creditActuel || 0);
            const creditInitial = parseFloat(this.clientSelected?.current_remboursement_amount || 0);

            if (creditInitial > 0) {
                const creditPercentage = (creditActuel / creditInitial) * 100;
                if (creditPercentage <= 25) return "border-danger";
                if (creditPercentage <= 50) return "border-warning";
            }
            return "border-success";
        },
       // Calcul des Totaux Globaux
        totalHT() {
        return this.reservation_produits.reduce((sum, prod) => {
            return sum + Number(prod.total_ht ?? 0);
        }, 0);
        },
        totalTTC() {
        return this.reservation_produits.reduce((sum, prod) => {
            return sum + Number(prod.cout_total ?? 0);
        }, 0);
        },
        totalTVA() {
        return this.reservation_produits.reduce((sum, prod) => {
            return sum + Number(prod.total_tva ?? 0);
        }, 0);
        },
        totalCSS() {
        return this.reservation_produits.reduce((sum, prod) => {
            return sum + Number(prod.total_css ?? 0);
        }, 0);
        },
        totalGarde() {
        return this.reservation_produits.reduce((sum, prod) => {
            return sum + Number(prod.total_garde ?? 0);
        }, 0);
        },
        totalPriseEnCharge() {
        return this.reservation_produits.reduce((sum, prod) => {
            return sum + Number(prod.total_prise_en_charge ?? 0);
        }, 0);
        },
        montant() {
            return this.reservation_produits.reduce((sum, prod) => {
                return sum + Number(prod.cout_total ?? 0);
            }, 0);
        },

    },
    methods: {
        calculateCredit() {
            if (this.clientSelected) {
                const creditInitial = parseFloat(this.clientSelected.current_remboursement_amount || 0);
                const totalPriseEnCharge = parseFloat(this.totalPriseEnCharge || 0);

                // Calculer le crédit restant
                const creditRestant = creditInitial - totalPriseEnCharge;
                this.localReservation.creditRestant = creditRestant > 0 ? creditRestant.toFixed(2) : "0.00";
            }
        },
        updatePercent(percent) {
            this.clientRemisePercent = parseFloat(percent)
            this.keyShow++
            this.keySelection++
        },
        checkGardeActive() {
            this.isLoading = true
            this.axios.get(this.baseUrl + '/api/gardes/actuelle').then((response) => {
                if (response.data && response.data.data) {
                    this.gardeActive = response.data.data
                }
                this.isReady = true
                this.isLoading = false
            }).catch(() => {
                this.isLoading = false
                this.$toast.add({
                    severity: 'error',
                    summary: 'Probleme de connexion',
                    detail: 'Une erreur s\'est produite lors de la connexion au serveur !',
                    life: 5000
                });
            })
        },
        updateNbProduit(lines) {
            this.nb_products = lines
        },
       
        
        selectReservation(reserv) {
            this.selectedReservation = reserv;
            this.globalReservation = reserv;
            this.key++
        },
        
        getCaisses() {
            this.isLoading = true
            this.axios.get(this.baseUrl + '/api/caisses?statut=Ouvert').then(response => {
                this.isLoading = false
                this.caisses = response.data.data
            }).catch(() => {
                this.isLoading = false
                this.$toast.add({
                    severity: 'error',
                    summary: 'Probleme de connexion',
                    detail: 'Une erreur s\'est produite lors de la connexion au serveur !',
                    life: 5000
                });
            })
        },
        getClients() {
            this.isLoading = true
            this.axios.get(this.baseUrl + '/api/clients').then(response => {
                this.isLoading = false
                this.clients = response.data.data
                this.setClientLibelle(false)
            }).catch(() => {
                this.isLoading = false
                this.$toast.add({
                    severity: 'error',
                    summary: 'Probleme de connexion',
                    detail: 'Une erreur s\'est produite lors de la connexion au serveur !',
                    life: 5000
                });
            })
        },
        setCaisseId(caisse) {
            this.localReservation.caisse_id = caisse.id
            this.localReservation.caisse = caisse.libelle
            this.autoSave();
        },
      
        setClientLibelle() { 
        const client = this.clients.find((item) => item.id === this.localReservation.client_id) || null;
        console.log("Client sélectionné :", client);

        if (client) {
            this.clientSelected = client;
            this.localReservation.client = client.libelle;
            console.log("LocalReservation.client mis à jour :", this.localReservation.client);

            if (client.prise_en_charge) {
                this.reservation_produits.forEach((product) => {
                    product.prise_en_charge = client.prise_en_charge;
                    product.cout_total = product.prix_de_vente * (1 - client.prise_en_charge / 100);
                });
                console.log("Produits mis à jour avec prise en charge :", this.reservation_produits);
            }
        } else {
            this.clientSelected = null;
            this.localReservation.client = "COMPTANT";
            console.log("Aucun client sélectionné. LocalReservation.client défini à 'COMPTANT'.");
        }

        // Calculer le crédit restant
        this.calculateCredit();

        // Appeler la méthode pour mettre à jour le client dans le LocalStorage
        this.updateClientInLocalStorage();
    },

    // Nouvelle méthode pour mettre à jour le client dans le LocalStorage
    async updateClientInLocalStorage() {
        try {
            await updateReservationInJson(this.localReservation.id, { 
                client_id: this.localReservation.client_id, 
                client: this.localReservation.client 
            });
            console.log("Client mis à jour dans le LocalStorage :", this.localReservation.client);
        } catch (error) {
            console.error("Erreur lors de la mise à jour du client dans le LocalStorage :", error);
        }
    },
  
    
    async selectReservation(reservation) { 
            this.selectedReservation = reservation; // ✅ Met à jour la réservation sélectionnée
            console.log("Réservation sélectionnée :", this.selectedReservation);
           

            // Charger les détails de la réservation
            await this.loadReservation(reservation);
        },

        async loadReservation(reservation) {
            if (reservation.status === 'Brouillon') {
                await this.loadReservationFromJson(reservation.id);
            } else {
                await this.loadReservationFromDatabase(reservation.id);
            }
        },

        async loadReservationFromJson(reservationId) {
            try {
                const jsonData = await readJsonFromFile();

                if (jsonData && jsonData.reservations) {
                    const reservation = jsonData.reservations.find(res => res.id === reservationId);
                    if (reservation) {
                        this.localReservation = { ...reservation };
                        console.log("LocalReservation mis à jour :", this.localReservation);

                        // Charger les produits liés
                        this.reservation_produits = jsonData.reservation_produits.filter(
                            prod => prod.reservation_id === reservationId
                        );

                        // Mettre à jour les produits sélectionnés
                        this.produits_selected = this.reservation_produits.map(prod => prod.produit_id);

                        console.log("Réservation et produits chargés depuis LocalStorage :", reservation);

                        // Mettre à jour clientSelected
                        this.clientSelected = this.clients.find(client => client.id === this.localReservation.client_id) || null;
                        console.log("Client sélectionné après chargement :", this.clientSelected);

                        // Calculer le crédit restant après avoir chargé la réservation
                        this.calculateCredit();
                    } else {
                        console.warn("Aucune réservation trouvée dans LocalStorage.");
                    }
                }
            } catch (error) {
                console.error("Erreur lors de la lecture de LocalStorage :", error);
                this.$toast.add({
                    severity: 'error',
                    summary: 'Erreur',
                    detail: "Une erreur s'est produite lors de la lecture des données de réservation.",
                    life: 5000,
                });
            }
        },


        async loadReservationFromDatabase(reservationId) {
            console.log("Chargement de la réservation depuis la base de données :", reservationId);
            try {
                // Première requête pour obtenir les détails de la réservation
                const response = await this.axios.get(`${this.baseUrl}/api/reservations/${reservationId}`);
                const reservation = response.data.data;
                this.localReservation = reservation;
                this.globalReservation = reservation;

                if (!this.localReservation.client) {
                    this.localReservation.client = "COMPTANT";
                }

                this.setClientLibelle();
                console.log("Réservation chargée depuis la base de données :", reservation);

                // Vérifier si les produits sont inclus dans la réponse
                if (reservation.reservation_produits && Array.isArray(reservation.reservation_produits)) {
                    this.reservation_produits = reservation.reservation_produits.map(prod => ({
                        ...prod,
                        qte: Number(prod.qte), // Convertir qte en nombre si nécessaire
                        prix_de_vente: Number(prod.prix_de_vente),
                        prix_achat: Number(prod.prix_achat),
                        // ... convertir d'autres champs si nécessaire
                    }));
                    console.log("Produits de la réservation :", this.reservation_produits);

                    // Mettre à jour le LocalStorage avec les produits (si nécessaire)
                    for (const produit of this.reservation_produits) {
                        await addProductToReservation(reservationId, produit);
                    }
                } else {
                    // Si les produits ne sont pas inclus dans la réponse, faire une deuxième requête
                    console.warn("Aucun produit trouvé dans la réponse de la réservation. Chargement séparé des produits.");
                    const produitsResponse = await this.axios.get(`${this.baseUrl}/api/reservation_produits?reservation_id=${reservationId}`);

                    // Vérifier si la réponse contient un tableau de produits
                    if (Array.isArray(produitsResponse.data.data)) {
                        this.reservation_produits = produitsResponse.data.data.map(prod => ({
                            ...prod,
                            qte: Number(prod.qte), // Convertir qte en nombre si nécessaire
                            prix_de_vente: Number(prod.prix_de_vente),
                            prix_achat: Number(prod.prix_achat),
                            // ... convertir d'autres champs si nécessaire
                        }));
                        console.log("Produits chargés séparément :", this.reservation_produits);

                        // Mettre à jour le LocalStorage avec les produits (si nécessaire)
                        for (const produit of this.reservation_produits) {
                            await addProductToReservation(reservationId, produit);
                        }
                    } else {
                        // Gérer le cas où la réponse ne contient pas un tableau de produits
                        console.warn("Échec du chargement des produits de la réservation :", produitsResponse.data);
                    }
                }
            } catch (error) {
                console.error("Erreur lors du chargement de la réservation depuis la base de données :", error);
                this.$toast.add({
                    severity: 'error',
                    summary: 'Problème de connexion',
                    detail: 'Impossible de charger la réservation depuis la base de données.',
                    life: 5000
                });
            }
        },

        autoSave() {
            if (this.debounceTimer) clearTimeout(this.debounceTimer);
            this.debounceTimer = setTimeout(() => {
                this.saveToJsonFile();
            }, 500); // Sauvegarde après 500ms de pause
        },

        async saveToJsonFile() {
            if (this.isSavingJson) {
                console.warn("Sauvegarde en cours, veuillez patienter...");
                return;
            }

            this.isSavingJson = true; // Verrouille la sauvegarde
            this.isSaving = true;

            try {
                const jsonData = await readJsonFromFile();

                if (!jsonData) {
                    console.warn("LocalStorage vide. Initialisation avec des données vides.");
                    jsonData = { reservations: [], reservation_produits: [] };
                }

                // Rechercher la réservation existante
                const existingReservationIndex = jsonData.reservations.findIndex(res => res.id === this.localReservation.id);
                
                if (existingReservationIndex !== -1) {
                    // Mise à jour de la réservation existante
                    jsonData.reservations[existingReservationIndex] = {
                        ...jsonData.reservations[existingReservationIndex], // Préserver les données existantes
                        ...this.localReservation, // Mettre à jour avec les nouvelles données
                    };
                } else {
                    // Ajouter une nouvelle réservation si elle n'existe pas
                    jsonData.reservations.push(this.localReservation);
                }

                // Préserver la table des produits
                jsonData.reservation_produits = jsonData.reservation_produits.filter(
                    prod => prod.reservation_id !== this.localReservation.id
                ).concat(this.reservation_produits);

                // Sauvegarder dans LocalStorage
                await saveJsonToFile(jsonData);

                console.log("LocalStorage mis à jour avec succès.");
            } catch (error) {
                console.error("Erreur lors de la sauvegarde dans LocalStorage :", error);
                this.$toast.add({
                    severity: "error",
                    summary: "Erreur",
                    detail: "Échec de la sauvegarde dans LocalStorage.",
                    life: 5000,
                });
            } finally {
                this.isSavingJson = false; // Libérer le verrou
                this.isSaving = false;
            }
        },


        async toggleProduitLocal(line, action) {
            if (!line || !line.id) {
                console.error("Erreur : Produit invalide");
                return;
            }

            try {
                const jsonData = await readJsonFromFile();

                if (!jsonData) {
                    console.error("LocalStorage vide ou introuvable.");
                    return;
                }

                if (action === "add") {
                    // Vérifie si le produit existe déjà dans LocalStorage
                    const existingProductIndex = jsonData.reservation_produits.findIndex(
                        (prod) => prod.produit_id === line.id && prod.reservation_id === this.localReservation.id
                    );

                    if (existingProductIndex === -1) {
                        // Produit non trouvé : Ajout d'un nouveau produit
                        this.produits_selected.push(line.id);

                        const result = calculerTotalProduits(line, 1, this.gardeActive, 0);

                        const newItem = {
                            libelle: line.libelle,
                            qte: 1,
                            prix_de_vente: line.prix_de_vente,
                            prix_achat: line.prix_achat,
                            cout_total: result.totalClient,
                            cout_total_reel: result.total,
                            produit: { 
                                ...line, 
                                produit_qte: line.qte,
                                qte:1,
                            },
                            reservation_id: this.localReservation.id,
                            produit_qte: line.qte,
                            prise_en_charge: this.clientSelected?.prise_en_charge || 0,
                            produit_id: line.id,
                            total_ht: result.totalHorsTaxe,
                            total_tva: result.totalTVA,
                            total_css: result.totalCSS,
                            total_prise_en_charge: result.totalPriseEnCharge,
                            total_garde: result.totalGarde,
                            is_sold: false,
                            vente_id: null,
                        };

                        jsonData.reservation_produits.push(newItem);
                        this.reservation_produits.push(newItem);
                    } else {
                        // Produit déjà existant : Mise à jour de la quantité
                        jsonData.reservation_produits[existingProductIndex].qte += 1;
                        this.reservation_produits[existingProductIndex].qte += 1;
                    }
                } else if (action === "remove") {
                    this.produits_selected = this.produits_selected.filter((pid) => pid !== line.id);
                    this.reservation_produits = this.reservation_produits.filter((prod) => prod.produit_id !== line.id);
                    jsonData.reservation_produits = jsonData.reservation_produits.filter(
                        (prod) => prod.produit_id !== line.id || prod.reservation_id !== this.localReservation.id
                    );
                }

                // 📥 Utilisation du débounce pour sauvegarder les données dans LocalStorage
                this.debounceSaveJson(jsonData);

                this.$toast.add({
                    severity: "success",
                    detail: action === "add" ? "Produit ajouté avec succès." : "Produit supprimé avec succès.",
                    life: 3000,
                });

            } catch (error) {
                console.error("Erreur lors de la mise à jour de LocalStorage :", error);
            }
        },


        // ✅ Méthode de sauvegarde avec débounce
        debounceSaveJson: _.debounce(async function (jsonData) {
            if (this.isSavingJson) {
                console.warn("Sauvegarde en cours, veuillez patienter...");
                return;
            }
            this.isSaving = true;
            this.isSavingJson = true; // Verrouille la sauvegarde

            try {
                await saveJsonToFile(jsonData);

                // Optionnel : Afficher un toast de succès
                /*
                this.$toast.add({
                    severity: "success",
                    detail: "LocalStorage mis à jour avec succès.",
                    life: 3000,
                });
                */
            } catch (error) {
                console.error("Erreur lors de la sauvegarde dans LocalStorage :", error);
                this.$toast.add({
                    severity: "error",
                    summary: "Erreur",
                    detail: "Échec de la sauvegarde dans LocalStorage.",
                    life: 5000,
                });
            } finally {
                this.isSavingJson = false; // Libère le verrou
                this.isSaving = false;
            }
        }, 500),
        


        async updateLineLocal(line, action, newValue) {
            if (action === 'remove') {
                this.reservation_produits = this.reservation_produits.filter(
                    (p) => Number(p.produit_id) !== Number(line.produit_id)
                );
                this.produits_selected = this.produits_selected.filter(
                    (pid) => pid !== Number(line.produit_id)
                );
            } else if (action === 'qte') {
                line.qte = Number(newValue) || 1;
            } else if (action === 'prise_en_charge') {
                line.prise_en_charge = Number(newValue) || 0;
            }

            // Recalculer les totaux
            if (action !== 'remove') {
                const result = calculerTotalProduits(line, line.qte, this.gardeActive, line.prise_en_charge);
                line.total_tva = result.totalTVA;
                line.total_css = result.totalCSS;
                line.total_ht = result.totalHorsTaxe;
                line.total_garde = result.totalGarde;
                line.total_prise_en_charge = result.totalPriseEnCharge;
                line.cout_total = result.totalClient;
                line.cout_total_reel = result.totalClient;
            }

            // Mettre à jour LocalStorage
            await this.saveToJsonFile();
        },


        // ✅ Sauvegarde dans le fichier JSON
        async saveToLogFile() {
            try {
                const jsonData = await readJsonFromFile();

                // Ajoute la réservation si elle n'existe pas encore
                if (!jsonData.reservations.find(r => r.id === this.localReservation.id)) {
                    jsonData.reservations.push(this.localReservation);
                }

                // Ajoute les produits de la réservation
                this.reservation_produits.forEach(product => {
                    if (!jsonData.reservation_produits.find(p => p.produit_id === product.produit_id && p.reservation_id === product.reservation_id)) {
                        jsonData.reservation_produits.push(product);
                    }
                });

                // Sauvegarde dans LocalStorage
                await saveJsonToFile(jsonData);

                this.$toast.add({
                    severity: "success",
                    detail: "LocalStorage mis à jour avec succès.",
                    life: 3000,
                });
            } catch (err) {
                console.error("Erreur lors de la sauvegarde de LocalStorage:", err);
            }
        },

        async removeProductFromJson(productId, reservationId) {
            try {
                const jsonData = await readJsonFromFile();

                if (!jsonData) {
                    console.warn("LocalStorage vide.");
                    return;
                }

                // Supprimer le produit correspondant
                jsonData.reservation_produits = jsonData.reservation_produits.filter(
                    (prod) => !(prod.produit_id === productId && prod.reservation_id === reservationId)
                );

                // Sauvegarder les changements dans LocalStorage
                await saveJsonToFile(jsonData);

                console.log(`Produit ID ${productId} supprimé de LocalStorage.`);
            } catch (error) {
                console.error("Erreur lors de la suppression du produit dans LocalStorage :", error);
            }
        },
        async handleReservationUpdated(updatedReservation) {
            try {
                // Rafraîchir les réservations
                await this.refreshReservations();

                // Sélectionner la réservation mise à jour
                await this.selectReservation(updatedReservation);

                console.log("Réservation mise à jour et sélectionnée dans le parent.");
            } catch (error) {
                console.error("Erreur lors de la gestion de la réservation mise à jour :", error);
                this.$toast.add({
                    severity: 'error',
                    summary: 'Erreur',
                    detail: "Une erreur s'est produite lors de la gestion de la réservation mise à jour.",
                    life: 5000
                });
            }
        },
        /**
         * Rafraîchir les réservations depuis le backend ou le localStorage
         */
        async refreshReservations() {
            try {
                const jsonData = await readJsonFromFile(); // Assurez-vous que readJsonFromFile est accessible
                if (jsonData && jsonData.reservations) {
                    this.reservations = jsonData.reservations;
                    console.log("Réservations rafraîchies dans le parent :", this.reservations);
                } else {
                    this.reservations = [];
                    console.warn("Aucune réservation trouvée lors du rafraîchissement dans le parent.");
                }
            } catch (error) {
                console.error("Erreur lors du rafraîchissement des réservations dans le parent :", error);
                this.$toast.add({
                    severity: 'error',
                    summary: 'Erreur',
                    detail: "Impossible de rafraîchir les réservations.",
                    life: 5000
                });
            }
        },

        createFacture() {
            this.devisIsLoading = true
            let FactureForm = {
                client: this.localReservation.client,
                reservation_id: this.localReservation.id,
                net_a_payer: this.localReservation.montant,
                type: 'Devis',
                created_by: this.$store.getters.user.fullname
            }
            this.axios.post(this.baseUrl + '/api/factures', FactureForm).then((response) => {
                if (response.data.success == true) {
                    this.updateReservation()
                    this.devisIsLoading = false
                }
                else {
                    response.data.errors.forEach(element => {
                        this.$toast.add({
                            severity: 'warn',
                            summary: 'Oups !',
                            detail: element,
                            life: 7000
                        });
                    });
                    this.devisIsLoading = false
                }
            })
        },
        transformToInvoice() {
            this.localReservation.status = 'Devis'
            this.localReservation.switch_devis_at = new Date().toLocaleString('fr-FR')
            this.createFacture()
        },
        isTreatable() {
            if (this.localReservation.status != 'Termine' && this.localReservation.status != 'Annule' && this.localReservation.status != 'Devis') {
                return true
            } else return false
        },
        
        commandProduit(id) {
            this.isLoadingInput[id] = true;
            this.axios.post(this.$store.state.api_epharma_stock + '/api/commandes/ajouter-produit/' + id).then(response => {
                if (response.data.success === true) {
                    this.$toast.add({
                        severity: 'success',
                        detail: response.data.message,
                        life: 3000
                    });
                }
                this.isLoadingInput[id] = false;
            }).catch(() => {
                this.isLoadingInput[id] = false;
                this.$toast.add({
                    severity: 'error',
                    summary: 'Probleme de connexion',
                    detail: 'Une erreur s\'est produite lors de la connexion au serveur !',
                    life: 5000
                });
            })
        },
        async getReservationProduits(id) {
            this.isLoading = true;

            try {
                // 📂 Charger les données depuis le fichier JSON (LocalStorage)
                const jsonData = await readJsonFromFile();

                if (jsonData && jsonData.reservations) {
                    const reservation = jsonData.reservations.find(res => res.id === id);

                    if (!reservation) {
                        console.warn(`Réservation ID ${id} non trouvée dans LocalStorage.`);
                        return;
                    }

                    if (reservation.status === "Brouillon") {
                        // 🔗 Filtrer les produits correspondant à la réservation en cours
                        this.reservation_produits = jsonData.reservation_produits.filter(
                            (product) => product.reservation_id === id
                        );

                        this.produits_selected = this.reservation_produits.map((item) => item.produit_id);
                        console.log('Produits de la réservation depuis LocalStorage :', this.reservation_produits);
                    } else {
                        // 🌐 Si vous souhaitez conserver la possibilité de charger depuis l'API pour les statuts autres que "Brouillon",
                        // vous pouvez conserver cet appel. Sinon, gérez toutes les données via LocalStorage.

                        // Exemple : Charger depuis LocalStorage même pour d'autres statuts
                        this.reservation_produits = jsonData.reservation_produits.filter(
                            (product) => product.reservation_id === id
                        );

                        this.produits_selected = this.reservation_produits.map((item) => item.produit_id);
                        console.log('Produits de la réservation depuis LocalStorage (autres statuts) :', this.reservation_produits);

                        // Si vous souhaitez afficher un message ou gérer différemment les autres statuts :
                        /*
                        console.warn(`La réservation ID ${id} n'est pas en statut "Brouillon".`);
                        */
                    }
                } else {
                    console.warn('Aucune réservation trouvée dans LocalStorage.');
                }
            } catch (error) {
                console.error("Erreur lors de la récupération des produits de la réservation depuis LocalStorage :", error);
                this.$toast.add({
                    severity: 'error',
                    summary: 'Erreur',
                    detail: "Une erreur s'est produite lors de la récupération des produits de la réservation.",
                    life: 5000,
                });
            } finally {
                this.isLoading = false;
            }
        },

        fillProduitsSelected() {
            this.produits_selected = []; // Réinitialiser le tableau pour éviter les doublons

            if (Array.isArray(this.reservation_produits)) {
                this.reservation_produits.forEach((produitReserved) => {
                    const produitId = produitReserved.produit_id;
                    if (!this.produits_selected.includes(produitId)) {
                        this.produits_selected.push(produitId);
                    }
                });
                console.log("produits_selected :", this.produits_selected);
            }
        },
    },
    async mounted() {
        this.getCaisses()
        this.getClients()
        try {
            // Initialiser le fichier JSON si nécessaire
            await initializeJsonFile();

            

            // Vérifier si une réservation brouillon est disponible dans le fichier JSON
            const jsonData = await readJsonFromFile();

            if (jsonData && jsonData.reservations) {
                const brouillonReservation = jsonData.reservations.find(res => res.status === 'Brouillon');
                if (brouillonReservation) {
                    await this.loadReservationFromJson(brouillonReservation.id);
                }
            }
        } catch (error) {
            console.error("Erreur lors du montage du composant :", error);
            this.$toast.add({
                severity: 'error',
                summary: 'Erreur',
                detail: "Une erreur s'est produite lors de l'initialisation du composant.",
                life: 5000,
            });
        }
    },
    watch: {
    totalPriseEnCharge: {
        handler() {
            this.calculateCredit();
        },
        immediate: true
    }
}


});
</script>
<style scoped>
.card .position-relative {
    position: relative;
}

.overlay {
    position: absolute;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
    background-color: rgba(128, 128, 128, 0.2);
    /* Grise l'arrière-plan */
    z-index: 1050;
    pointer-events: all;
    /* Désactive les interactions sur les éléments sous l'overlay */
}

.watermark {
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(30deg);
    border-style: dashed;
    color: #ff0022;
    font-size: 2.1em;
    font-weight: bold;
    opacity: 0.5;
    z-index: 1060;
    /* z-index: 1; */
    pointer-events: none;
    /* Désactive les clics sur le filigrane */
}

.border-primary {
    border-color: #007bff !important;
}

.text-primary {
    color: #007bff !important;
}

.bg-danger {
    background-color: #dc3545 !important;
}

.border-danger {
    border-color: #dc3545 !important;
}

.text-white {
    color: #fff !important;
}

.h-fixed {
    height: 70vh;
    overflow-y: auto;
}

.search-form {
    font-size: 1.2rem;
    padding: 0.75rem;
}
</style>