<template>
    <!-- Boutons d'action : Payer maintenant, Imprimer, Billetage -->
    <div class="d-flex">
        <!-- S'affiche si la réservation est 'traitable' -->
        <button 
            type="button" 
            class="btn btn-success mr-2" 
            @click="showModal = true" 
            v-if="isTreatable"
        >
            <i class="fa-solid fa-check mr-1"></i> Payer maintenant
        </button>

        <!-- S'affiche si la réservation n'est pas Annulée ni Devis -->
        <button 
            v-else-if="reservation.status !== 'Annule' && reservation.status !== 'Devis'"
            type="button" 
            class="btn btn-dark mr-2" 
            @click="printReceipt"
        >
            <i class="fa-solid fa-print mr-1"></i> Imprimer
        </button>

        <button 
            type="button" 
            class="btn btn-dark mr-2" 
            @click="openBilletage"
        >
            <i class="fa-solid fa-bills mr-1"></i> Billetage
        </button>
    </div>

    <!-- Modal PrimeVue -->
    <Dialog header="Règlement" v-model:visible="showModal" modal>
        <form @submit.prevent="submitForm">
            <div class="bg-black mt-3 p-3 rounded-sm mb-3 text-white">
                
                <!-- Remise (modifiable) -->
                <div class="form-group">
                    <label class="text-gri3">Remise</label>
                    <!-- [MODIF] On utilise v-model pour que l'utilisateur puisse taper la remise -->
                    <input 
                        type="number"
                        v-model="remiseValue"
                        class="form-control border border-dark px-[4rem]"
                    />
                </div>

                <!-- Montant reçu (modifie la difference dynamiquement) -->
                <div class="form-group">
                    <label class="text-gri3">Montant reçu</label>
                    <input 
                        type="number" 
                        v-model="amount_gived" 
                        class="form-control border border-dark px-[4rem]"
                        required 
                        :disabled="reservation.switch_finish_at"
                    />
                </div>

                <!-- Différence (lecture seule) -->
                <div class="form-group">
                    <label class="text-gri3">Différence (Rendu)</label>
                    <input
                        type="number"
                        :value="difference"
                        class="form-control border border-dark px-[4rem]"
                        disabled
                    />
                </div>
            </div>

            <button type="submit" class="btn btn-dark btn-block">
                <i class="fa-solid fa-save mr-1"></i> Enregistrer le paiement
                <span 
                    v-if="isLoading" 
                    class="spinner-border spinner-border-sm" 
                    role="status" 
                    aria-hidden="true"
                ></span>
            </button>
        </form>
    </Dialog>

    <!-- Container du ticket, caché en temps normal -->
    <div 
        class="container" 
        ref="ticket_caisse" 
        v-show="false"
    >
        <!-- Première section : Logo et Infos de la Pharmacie -->
        <div class="section center">
            <img 
                v-if="$store.getters.pharmacie.logo" 
                :src="$store.getters.pharmacie.logo"
                alt="logo_pharmacie" 
                id="logo_pharmacie" 
                class="rounded mb-3" 
                style="width: 100px"
            />
            <p class="center" style="padding: 20px;">
                {{ getInfoPharmacie }}
            </p>
        </div>

        <!-- Deuxième section : Infos de l'Assuré -->
        <div class="section" v-if="reservation.client !== 'COMPTANT'">
            <h4>Informations du client</h4>
            <p>
                Nom : {{ reservation.client }}<br>
                <span v-if="reservation.identifiant_assure">
                    Numéro Assuré : {{ reservation.identifiant_assure }}<br>
                </span>
                <span v-if="reservation.secteur_assure">
                    Secteur : {{ reservation.secteur_assure }}<br>
                </span>
                <span v-if="reservation.numero_feuille_assure">
                    N° feuille : {{ reservation.numero_feuille_assure }}<br>
                </span>
            </p>
        </div>

        <!-- Troisième section : Détails de la Caisse -->
        <div class="section">
            <h4>Détails de la Caisse</h4>
            <p>
                Numéro : {{ reservation.position }}<br>
                Date : 
                {{ new Date().toLocaleString('fr-FR', {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                    hour: '2-digit',
                    minute: '2-digit'
                }) }}
                <br>
                Vendeur(se) : {{ $store.getters.user.fullname }}<br>
                <span v-if="caisse.libelle !== 'Default'">
                    Caisse : {{ caisse.libelle }}
                </span>
            </p>
        </div>

        <!-- Quatrième section : Liste des Médicaments -->
        <div class="section">
            <h4>Liste des Médicaments</h4>
            <table>
                <thead>
                    <tr>
                        <th>Produit</th>
                        <th>Qté</th>
                        <th>Coût</th>
                    </tr>
                </thead>
                <tbody>
                    <tr 
                        v-for="(item, index) in reservation_produits" 
                        :key="index"
                    >
                        <td>{{ item.libelle }}</td>
                        <td>{{ item.qte }}</td>
                        <td>{{ $moneyFormat(item.prix_de_vente) }}</td>
                    </tr>
                </tbody>
            </table>

            <!-- Total Section -->
            <div class="total-section">
                <table>
                    <thead>
                        <tr>
                            <th colspan="6">Total TTC</th>
                            <td>
                                {{ (parseFloat(reservation.montant) + parseFloat(reservation.totalPriseEnCharge)).toFixed(2) }}
                            </td>
                        </tr>

                        <tr 
                            v-if="reservation.client !== 'COMPTANT' 
                                   && parseFloat(reservation.totalPriseEnCharge) > 0"
                        >
                            <th colspan="6">Prise en Charge</th>
                            <td>{{ $moneyFormat(reservation.totalPriseEnCharge) }}</td>
                        </tr>
                        <tr
                            v-if="reservation.client !== 'COMPTANT' 
                                   && parseFloat(reservation.totalPriseEnCharge) > 0"
                        >
                            <th colspan="6">Ticket Modérateur</th>
                            <td>
                                {{ $moneyFormat(reservation.montant) }}
                            </td>
                        </tr>

                        <tr v-if="amount_gived">
                            <th colspan="6">Montant Reçu</th>
                            <td>{{ amount_gived }}</td>
                        </tr>
                        <tr v-if="amount_gived">
                            <th colspan="6">Différence</th>
                            <td>{{ difference }}</td>
                        </tr>
                    </thead>
                </table>
            </div>
        </div>

        <!-- Cinquième section : Slogan de la Pharmacie -->
        <p 
            class="section center slogan" 
            v-if="$store.getters.pharmacie.slogan" 
            style="margin-bottom: 20px;"
        >
            "{{ $store.getters.pharmacie.slogan }}"
        </p>

        <div id="overlay" class="overlay "></div>
        <!-- filigrane pour marquer le statut de la reservation -->
        <div  
            v-if="duplicata" 
            class="watermark"
        >
            DUPLICATA
        </div>

        <div class="footer">
            <strong>{{ $store.getters.pharmacie.nom_pharmacie }}.</strong><br>
            {{ $store.getters.pharmacie.adresse }} | BP: <br>
            TEL: {{ $store.getters.pharmacie.telephone1 }} / {{ $store.getters.pharmacie.telephone2 }} |
            Email: {{ $store.getters.pharmacie.email }}<br>
            {{ $store.getters.pharmacie.rccm }} | {{ $store.getters.pharmacie.nif }}
        </div>
    </div>
</template>

<script>
const CreateBilletage = defineAsyncComponent(() => import('../../gestion/billetages/CreateBilletage.vue'));
import { defineComponent, defineAsyncComponent } from 'vue';
import {calculerTotalProduits} from '@/views/calculs/calcul-total-produit-reservation.ts'
import Dialog from 'primevue/dialog';
import { 
    addReservationToJson, 
    addProductToReservation, 
    readJsonFromFile, 
    updateReservationInJson, 
    initializeJsonFile,
    Reservation,
    ReservationProduit
} from '@/views/calculs/logUtils.ts';

export default defineComponent({
    name: "BuyReservation",
    props: {
        reservation: {
            type: Object,
            required: true
        },
        montantTTC: {
            type: Number,
            required: false,
            default: 0
        },
        remiseValue: {
            type: Number,
            required: false,
            default: 0
        },
        reservation_produits: {
            type: Array,
            required: true
        },
        clientSelected: {
            type: Object,
            required: true
        }
    },
    components: { Dialog },
    data() {
        return {
            isLoading: false,
            duplicata: true,
            baseUrl: this.$store.state.api_epharma_ventes,
            showModal: false,
            // Montant reçu par l'utilisateur
            amount_gived: 0,
            form: {},
            caisse: {
                libelle: 'Default'
            }
        }
    },
    computed: {
        // Calcule la différence en temps réel
        difference() {
            // On se base sur montantTTC
            const totalMnt = parseFloat(this.montantTTC) || 0;
            const diff = parseFloat(this.amount_gived) - totalMnt;
            // Retourner la valeur absolue pour enlever le signe négatif
            return isNaN(diff) ? "0.00" : Math.abs(diff).toFixed(2);
        },
        getInfoPharmacie() {
            let infos = 'Pharmacie ';
            if (this.$store.getters.pharmacie) {
                if (this.$store.getters.pharmacie.nom_pharmacie) {
                    infos = this.$store.getters.pharmacie.nom_pharmacie + ' | ';
                }
                if (this.$store.getters.pharmacie.statut_juridique) {
                    infos += this.$store.getters.pharmacie.statut_juridique + ' | ';
                }
                if (this.$store.getters.pharmacie.capital) {
                    infos += ' au capital de ' + this.$store.getters.pharmacie.capital + ' FCFA | ';
                }
                if (this.$store.getters.pharmacie.adresse) {
                    infos += this.$store.getters.pharmacie.adresse + ' | ';
                }
                if (this.$store.getters.pharmacie.bp) {
                    infos += ' BP: ' + this.$store.getters.pharmacie.bp + ' | ';
                }
                if (this.$store.getters.pharmacie.telephone1) {
                    infos += ' Tel: ' + this.$store.getters.pharmacie.telephone1 + ' | ';
                }
                if (this.$store.getters.pharmacie.rccm) {
                    infos += ' Rccm: ' + this.$store.getters.pharmacie.rccm + ' | ';
                }
                if (this.$store.getters.pharmacie.nif) {
                    infos += ' Nif: ' + this.$store.getters.pharmacie.nif + ' | ';
                }
                if (this.$store.getters.pharmacie.email) {
                    infos += ' Email: ' + this.$store.getters.pharmacie.email;
                }
            }
            return infos;
        },
        // Vérifie si la réservation est traitable
        isTreatable() {
            return this.reservation &&
                this.reservation.status !== 'Termine' &&
                this.reservation.status !== 'Annule' &&
                this.reservation.status !== 'Devis';
        }
    },
    mounted() {
         // Initialiser le localStorage si nécessaire
         initializeJsonFile();
        // Charger les réservations depuis le localStorage
        this.refreshReservations();
        // Les données sont passées via props, pas besoin de les récupérer ici
    },
    methods: {
        openBilletage() {
            this.$dialog.open(CreateBilletage, {
                props: {
                    header: "Enregistrement du billetage de la caisse Default",
                    style: {
                        width: '60vw',
                    },
                    modal: true
                },
                data: {
                    libelle: 'Default'
                }
            });
        },
        async submitForm() {
            // Calculer la différence entre le montant donné et le total TTC
            const totalMnt = parseFloat(this.montantTTC) || 0;
            const difference = parseFloat(this.amount_gived) - totalMnt;

            if (difference < 0) {
                this.$toast.add({
                    severity: 'warn',
                    summary: 'Oups !',
                    detail: 'La différence ne peut être négative',
                    life: 7000
                });
                return;
            }

            this.isLoading = true;

            try {
                // Initialiser les totaux globaux
                let totalHorsTaxe = 0;
                let totalTVA = 0;
                let totalCSS = 0;
                let totalPriseEnCharge = 0;
                let totalGarde = 0;
                let totalClient = 0;
                let total = 0;

                // Parcourir chaque produit pour calculer les totaux
                for (const line of this.reservation_produits) {
                    // Vérifier que 'produit' existe
                    if (!line.produit) {
                        throw new Error(`Produit manquant pour la ligne avec ID ${line.id}`);
                    }

                    // Vérifier que les prix sont valides
                    if (!line.produit.prix_de_vente || !line.produit.prix_achat) {
                        throw new Error(`Prix manquant pour le produit avec ID ${line.produit.id}`);
                    }

                    // Calculer les totaux pour le produit en tenant compte de la quantité
                    const qte = parseInt(line.qte);
                    if (isNaN(qte) || qte <= 0) {
                        throw new Error(`Quantité invalide pour le produit avec ID ${line.id}`);
                    }

                    // Récupérer la quantité disponible en stock
                    const qteDisponible = parseInt(line.produit.qte_max) || 0;
                    if (qte > qteDisponible) {
                        throw new Error(`La quantité sélectionnée pour le produit "${line.libelle}" dépasse le stock disponible (${qteDisponible}).`);
                    }

                    const result = calculerTotalProduits(line, qte, this.gardeActive, 0, this.remiseValue);

                    // Accumuler les totaux globaux
                    totalHorsTaxe += result.totalHorsTaxe;
                    totalTVA += result.totalTVA;
                    totalCSS += result.totalCSS;
                    totalPriseEnCharge += result.totalPriseEnCharge;
                    totalGarde += result.totalGarde;
                    totalClient += result.totalClient;
                    total += result.cout_total || 0;

                    // Log des résultats pour chaque produit
                    console.log(`Produit ID ${line.produit.id} - cout_total: ${result.cout_total}`);
                }

                // Ajouter des logs pour vérifier les valeurs accumulées
                console.log("Total Hors Taxe:", totalHorsTaxe);
                console.log("Total TVA:", totalTVA);
                console.log("Total CSS:", totalCSS);
                console.log("Total Prise En Charge:", totalPriseEnCharge);
                console.log("Total Garde:", totalGarde);
                console.log("Total Client:", totalClient);
                console.log("Total:", total);
                console.log("Montant TTC:", this.montantTTC);
                console.log("Montant Reçu:", this.amount_gived);

                // Préparer les données de la réservation à mettre à jour
                const updatedReservation = {
                    ...this.reservation,
                    status: 'Termine',
                    caisse: 'Default',
                    caisse_id: 1,
                    montant: (totalClient || 0).toFixed(2), // Correctement assigné à cout_total
                    switch_finish_at: new Date().toLocaleString('fr-FR'),
                    switch_finish_by: this.$store.getters.user.fullname,
                    amount_gived: (this.amount_gived || 0).toFixed(2),
                    
                    // total: (this.montantTTC || 0).toFixed(2), // Supprimé pour éviter la réinitialisation
                };

                // Log des données de réservation pour débogage
                console.log("Données de réservation mises à jour :", updatedReservation);

                // Envoyer les mises à jour de la réservation au backend
                const reservationResponse = await this.axios.post(
                    `${this.baseUrl}/api/reservations/${this.reservation.id}/update`,
                    updatedReservation
                );

                console.log("Réponse de l'API pour la réservation :", reservationResponse.data);

                if (reservationResponse.data.success === true) {
                    // Ajouter les produits associés un par un
                    for (const line of this.reservation_produits) {
                        await this.addReservationProduitLine(updatedReservation.id, line);
                    }

                    // Mettre à jour le LocalStorage avec le nouveau statut
                    await updateReservationInJson(updatedReservation.id, { status: 'Termine' });

                    // Émettre un événement pour informer le parent
                    this.$emit('reservation-updated', updatedReservation);

                    // Afficher le reçu
                    this.printReceipt();

                    // Notification de succès
                    this.$toast.add({
                        severity: "success",
                        summary: "Succès",
                        detail: "Vente enregistrée avec succès.",
                        life: 5000
                    });

                    // Fermer le modal
                    this.showModal = false;
                } else {
                    // Gestion des erreurs si la réponse n'est pas succès
                    if (reservationResponse.data.errors && Array.isArray(reservationResponse.data.errors)) {
                        reservationResponse.data.errors.forEach((element) => {
                            this.$toast.add({
                                severity: 'warn',
                                summary: 'Oups !',
                                detail: element,
                                life: 7000
                            });
                        });
                    } else {
                        this.$toast.add({
                            severity: 'warn',
                            summary: 'Oups !',
                            detail: 'Une erreur inconnue est survenue.',
                            life: 7000
                        });
                    }
                    throw new Error('Réservation update échouée');
                }
            } catch (error) {
                this.isLoading = false;
                console.error("Erreur lors de la soumission du formulaire :", error);
                if (!error.message.includes('échouée')) {
                    this.$toast.add({
                        severity: 'error',
                        summary: 'Problème de connexion',
                        detail: 'Une erreur s\'est produite lors de la connexion au serveur !',
                        life: 5000
                    });
                }
            } finally {
                this.isLoading = false;
            }
        },



        
        async addReservationProduitLine(reservationId, line) {
            // Marquer le produit comme en cours de chargement
            this.isLoadingInput = { ...this.isLoadingInput, [line.id]: true };

            try {
                // Vérifier le contenu de 'line'
                console.log("Données du produit avant envoi :", line);

                // Calculer les totaux pour le produit en tenant compte de la quantité
                const qte = parseInt(line.qte);
                if (isNaN(qte) || qte <= 0) {
                    throw new Error(`Quantité invalide pour le produit avec ID ${line.id}`);
                }

                const result = calculerTotalProduits(line, qte, this.gardeActive, 0, this.remiseValue);

                // Calculer les prix unitaires
                const prixDeVenteUnitaire = result.totalPrixVente > 0 ? (result.totalPrixVente / qte).toFixed(2) : "0.00";
                const prixAchatUnitaire = result.totalHorsTaxe > 0 ? (result.totalHorsTaxe / qte).toFixed(2) : "0.00";

                // Préparer les données du produit à envoyer à l'API
                const form = {
                    libelle: line.libelle,
                    qte: qte,
                    prix_de_vente: prixDeVenteUnitaire, // Prix unitaire
                    prix_achat: prixAchatUnitaire,     // Prix unitaire
                    cout_total: (result.totalClient || 0).toFixed(2), // Coût total calculé
                    cout_total_reel: (result.total || 0).toFixed(2),    // Coût total réel calculé
                    produit: { 
                        ...line.produit, 
                        produit_qte: line.produit_qte,
                        qte: qte,
                    },
                    reservation_id: reservationId,
                    produit_qte: line.produit_qte,
                    prise_en_charge: (result.totalPriseEnCharge || 0).toFixed(2), // Prise en charge calculée
                    produit_id: line.produit.id, // ID correct
                    total_ht: (result.totalHorsTaxe || 0).toFixed(2),
                    total_tva: (result.totalTVA || 0).toFixed(2),
                    total_css: (result.totalCSS || 0).toFixed(2),
                    total_prise_en_charge: (result.totalPriseEnCharge || 0).toFixed(2),
                    total_garde: (result.totalGarde || 0).toFixed(2),
                };

                // Vérifier le contenu de 'form' avant envoi
                console.log("Données envoyées à l'API pour le produit :", form);

                // Envoyer la requête POST pour créer le produit de réservation
                const response = await this.axios.post(`${this.baseUrl}/api/reservation_produits`, form);

                // Vérifier la réponse de l'API
                console.log("Réponse de l'API :", response.data);

                if (response.data.success === true) {
                    // Ajouter le produit au LocalStorage
                    await addProductToReservation(reservationId, response.data.data);

                    // Afficher une notification de succès
                    this.$toast.add({
                        severity: 'success',
                        detail: response.data.message || 'Produit ajouté avec succès.',
                        life: 3000
                    });
                } else {
                    // Si échec, afficher les erreurs
                    if (response.data.errors && Array.isArray(response.data.errors)) {
                        response.data.errors.forEach(element => {
                            this.$toast.add({
                                severity: 'warn',
                                summary: 'Oups !',
                                detail: element,
                                life: 7000
                            });
                        });
                    } else {
                        this.$toast.add({
                            severity: 'warn',
                            summary: 'Oups !',
                            detail: 'Une erreur inconnue est survenue.',
                            life: 7000
                        });
                    }
                    throw new Error('Ajout du produit de réservation échoué');
                }
            } catch (error) {
                // Gérer les erreurs de connexion ou autres erreurs
                this.$toast.add({
                    severity: 'error',
                    summary: 'Problème de connexion',
                    detail: 'Une erreur s\'est produite lors de la connexion au serveur !',
                    life: 5000
                });
                console.error("Erreur lors de la création du produit de réservation :", error);
                throw error; // Relancer l'erreur pour qu'elle soit gérée dans submitForm
            } finally {
                // Retirer l'indicateur de chargement
                this.isLoadingInput = { ...this.isLoadingInput, [line.id]: false };
            }
        },
        printReceipt() {
            const receiptContent = this.$refs.ticket_caisse.innerHTML;
            const printWindow = window.open("", "", "width=1000,height=900");

            // Charger le contenu et les styles dans la nouvelle fenêtre
            printWindow.document.write(`
            <!DOCTYPE html>
            <html lang="fr">
            <head>
                <meta charset="UTF-8">
                <meta name="viewport" content="width=device-width, initial-scale=1.0">
                <title>Ticket de Caisse</title>
                <style>
                    body {
                        font-family: Arial, sans-serif;
                        margin: 0;
                        padding: 20px;
                        background-color: #f4f4f4;
                    }
                    .container {
                        width: 350px;
                        background-color: #fff;
                        margin: 0 auto;
                        padding: 20px;
                        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
                        border-radius: 10px;
                    }
                    .overlay {
                        position: relative;
                        bottom: 70%;
                        left: 0%;
                        width: 100%;
                        height: 100%;   
                        z-index: 1;
                    }
                    .watermark {
                        position: relative;
                        bottom: 90%;
                        left: 80%;
                        transform: translate(-50%, -50%) rotate(20deg);
                        color: #FF0000;
                        font-size: 2em;
                        font-weight: bold;
                        opacity: 0.3;
                        z-index: 1; 
                        pointer-events: none;
                    }
                    .section {
                        margin-bottom: 20px;
                    }
                    .logo {
                        display: block;
                        margin: 0 auto;
                        width: 100px;
                    }
                    .center {
                        text-align: center;
                    }
                    table {
                        width: 100%;
                        border-collapse: collapse;
                    }
                    th, td {
                        padding: 8px;
                        border-bottom: 1px solid #ddd;
                        text-align: left;
                    }
                    th {
                        background-color: #e4e4e4;
                        color: rgb(0, 0, 0);
                    }
                    .total-section {
                        margin-top: 10px;
                    }
                    .total-section th {
                        background-color: #f8f8f8;
                        color: black;
                    }
                    .total-section td {
                        font-weight: bold;
                    }
                    .slogan {
                        font-style: italic;
                        margin-top: 20px;
                        font-size: 1.1em;
                    }
                    .footer {
                        background-color: #f1f1f1; 
                        text-align: center;
                        padding: 10px;
                        border-top: 1px solid #eee;
                        font-size: 14px;
                        color: #777;
                        width: 100%;
                        position: fixed;
                        bottom: 0;
                        left: 0;
                    }
                    .footer strong {
                        color: #333;
                    }
                </style>
            </head>
            <body>
                ${receiptContent}
            </body>
            </html>
            `);

            // Attendre que le contenu soit chargé avant d'imprimer
            printWindow.document.close();
            printWindow.focus();
            printWindow.print();
            printWindow.close();
            this.duplicata = true;
        },
        async refreshReservations() {
            try {
                const jsonData = await readJsonFromFile();
                if (jsonData && jsonData.reservations) {
                    this.reservations = jsonData.reservations;
                    console.log("Réservations mises à jour depuis LocalStorage :", this.reservations);
                } else {
                    this.reservations = [];
                    console.warn("Aucune réservation trouvée dans LocalStorage.");
                }
            } catch (error) {
                console.error("Erreur lors de la rafraîchissement des réservations :", error);
                this.$toast.add({
                    severity: 'error',
                    summary: 'Erreur',
                    detail: "Impossible de rafraîchir les réservations.",
                    life: 5000,
                });
            }
        }
    }
});
</script>

<style scoped>
/* Vos styles existants */
</style>
