<template>
  <div class="card-wrapper">
    <div class="card" @mouseover="showButton = true" @mouseleave="showButton = false">
      <div class="card-body">
        <ChartJS type="bar" :data="chartData" :options="chartOptions"></ChartJS>
        <button
          v-if="showButton"
          class="btn btn-primary export-btn"
          @click="openStatExportModal"
        >
          Exporter <i class="fas fa-file-export"></i>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Statistiques from "./ExportStatistique.vue"; // Composant pour afficher les statistiques

export default {
  name: "TopProduitsChart",
  components: { Statistiques },
  props: {
    filters: {
      type: Object,
      required: true,
    },
    period: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      baseUrl: this.$store.state.api_epharma_ventes,
      chartData: {
        labels: [],
        datasets: [
          {
            label: "Quantité vendue",
            backgroundColor: "#42A5F5",
            borderColor: "#1E88E5",
            borderWidth: 1,
            data: [],
          },
        ],
      },
      chartOptions: {
        responsive: true,
        indexAxis: "y",
        scales: {
          x: {
            beginAtZero: true,
            title: {
              display: true,
              text: "Quantité vendue",
            },
          },
          y: {
            title: {
              display: true,
              text: "Produits",
            },
          },
        },
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            mode: "index",
            intersect: false,
          },
        },
      },
      showButton: false,
      statData: [], // Données pour le tableau
      columns: ["Produit", "Quantité vendue"], // Colonnes du tableau
      TitleStat : "Produits les plus vendus",
      
    };
  }, 
  computed: {
    dataTable() {
      return {
        columns: this.columns,
        rows: this.statData,
        title: this.TitleStat,
      };
    },
  },
  methods: {
    openStatExportModal() {
      
      this.$dialog.open(Statistiques, {
        props: {
          header: "Statistiques",
          style: {
            width: "70vw",
          },
          modal: true,
        },
        data: this.dataTable
        
      });
    },
    fetchTopProduits() {
      let url = `${this.baseUrl}/api/stat/top20-produits-vendus`;

      if (this.filters.debut && this.filters.fin) {
        url += `?period_from=${this.filters.debut}&period_to=${this.filters.fin}`;
      } else if (this.filters.week) {
        const [year, week] = this.filters.week.split("-W");
        const firstDayOfWeek = new Date(year, 0, 1 + (week - 1) * 7);
        const lastDayOfWeek = new Date(firstDayOfWeek);
        lastDayOfWeek.setDate(firstDayOfWeek.getDate() + 6);
        url += `?period_from=${firstDayOfWeek.toISOString().slice(0, 10)}&period_to=${lastDayOfWeek.toISOString().slice(0, 10)}`;
      } else if (this.filters.month) {
        const [year, month] = this.filters.month.split("-");
        const firstDayOfMonth = new Date(year, month - 1, 1);
        const lastDayOfMonth = new Date(year, month, 0);
        url += `?period_from=${firstDayOfMonth.toISOString().slice(0, 10)}&period_to=${lastDayOfMonth.toISOString().slice(0, 10)}`;
      }

      this.axios
        .get(url)
        .then((response) => {
          const data = response.data;
          this.chartData.labels = data.map((item) => item.libelle);
          this.chartData.datasets[0].data = data.map((item) => item.total_vendu);
          this.statData = data.map((item) => ({
            Produit: item.libelle,
            "Quantité vendue": item.total_vendu,
          }));
        })
        .catch((error) => {
          console.error("Erreur lors de la récupération des données des produits :", error);
        });
    },
  },
  mounted() {
    this.fetchTopProduits();
  },
};
</script>

<style scoped>
.card-wrapper {
  position: relative;
}

.export-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.card:hover .export-btn {
  opacity: 1;
}
</style>
