<template>
  <div>
    <h3>Marge sur les achats</h3>
    <div class="card" @mouseover="showButton = true" @mouseleave="showButton = false">
      <div class="card-body">
        <canvas ref="margeChart"></canvas>
      </div>
      <button
        v-if="showButton"
        class="btn btn-primary export-btn"
        @click="openStatExportModal"
      >
        Exporter <i class="fas fa-file-export"></i>
      </button>
    </div>
  </div>
</template>

<script>
import {
  Chart,
  BarElement,
  BarController,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
} from "chart.js";
import Statistiques from "./ExportStatistique.vue"; // Composant modal pour les statistiques

Chart.register(BarElement, BarController, CategoryScale, LinearScale, Tooltip, Legend);

export default {
  name: "MargeAchat",
  components: { Statistiques },
  props: {
    filters: {
      type: Object,
      required: true,
    },
    period: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      chart: null,
      labels: [],
      data: [],
      baseUrl: this.$store.state.api_epharma_stock,
      showButton: false,
      statData: [],
      columns: ["Produit", "Marge (Bénéfice)"],
      TitleStat : "Marge sur les achats",
    };
  },
  computed: {
    dataTable() {
      return {
        columns: this.columns,
        rows: this.statData,
        title: this.TitleStat,
      };
    },
  },
  watch: {
    filters: {
      deep: true,
      handler() {
        this.fetchMargeData(); // Recharger les données si les filtres changent
      },
    },
    period: {
      immediate: true,
      handler() {
        this.fetchMargeData(); // Recharger les données si la période change
      },
    },
  },
  methods: {
    async fetchMargeData() {
      try {
        let url = `${this.baseUrl}/api/marge-benefice`;

        if (this.filters.debut && this.filters.fin) {
          url += `?period_from=${this.filters.debut}&period_to=${this.filters.fin}`;
        } else if (this.filters.week) {
          const [year, week] = this.filters.week.split("-W");
          const firstDayOfWeek = new Date(year, 0, 1 + (week - 1) * 7);
          const lastDayOfWeek = new Date(firstDayOfWeek);
          lastDayOfWeek.setDate(firstDayOfWeek.getDate() + 6);
          url += `?period_from=${firstDayOfWeek.toISOString().slice(0, 10)}&period_to=${lastDayOfWeek.toISOString().slice(0, 10)}`;
        } else if (this.filters.month) {
          const [year, month] = this.filters.month.split("-");
          const firstDayOfMonth = new Date(year, month - 1, 1);
          const lastDayOfMonth = new Date(year, month, 0);
          url += `?period_from=${firstDayOfMonth.toISOString().slice(0, 10)}&period_to=${lastDayOfMonth.toISOString().slice(0, 10)}`;
        }

        const response = await fetch(url);
        if (!response.ok) {
          throw new Error("Erreur lors de la récupération des données.");
        }

        const result = await response.json();

        this.labels = result.map((item) => item.produit_libelle);
        this.data = result.map((item) => item.marge);
        this.statData = result.map((item) => ({
          Produit: item.produit_libelle,
          "Marge (Bénéfice)": item.marge,
        }));

        this.$nextTick(() => this.renderChart());
      } catch (error) {
        console.error("Erreur lors de la récupération des données :", error);
      }
    },
    renderChart() {
      const canvas = this.$refs.margeChart;

      if (!canvas) {
        console.error("Le canvas n'est pas encore monté.");
        return;
      }

      const ctx = canvas.getContext("2d");

      if (this.chart) {
        this.chart.destroy();
      }

      this.chart = new Chart(ctx, {
        type: "bar",
        data: {
          labels: this.labels,
          datasets: [
            {
              label: "Marge sur les achats (Bénéfice)",
              data: this.data,
              backgroundColor: "rgba(75, 192, 192, 0.5)",
              borderColor: "rgba(75, 192, 192, 1)",
              borderWidth: 1,
            },
          ],
        },
        options: {
          responsive: true,
          plugins: {
            legend: {
              display: false,
            },
          },
          scales: {
            y: {
              beginAtZero: true,
            },
          },
        },
      });
    },
    openStatExportModal() {
      this.$dialog.open(Statistiques, {
        props: {
          header: "Statistiques - Marge sur les achats",
          style: {
            width: "70vw",
          },
          modal: true,
        },
        data: this.dataTable,
      });
    },
  },
  mounted() {
    this.fetchMargeData();
  },
};
</script>

<style scoped>
canvas {
  max-width: 100%;
  height: auto;
}

.export-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.card:hover .export-btn {
  opacity: 1;
}

.export-btn i {
  margin-left: 5px;
}
</style>
