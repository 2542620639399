import store from '../../store'; // ou le chemin correct

/**
 * Types de base (simplifiés).
 */
export interface Garde {
  numero: string;
  date_debut: Date;
  date_fin: Date;
  heure_debut: TimeRanges;
  heure_fin: TimeRanges;
  montant_taxe: string | number;
  total_taxe: string;
  is_active: string;
}

export interface Produit {
  libelle: string;
  cip: string;
  prix_achat: number | string;
  coef_conversion_de_prix_vente_achat: number | string;
  code: string;
  qte: number | string;
  qte_max: number | string;
  qte_min: number | string;
  description: string;
  ean: string;
  dci: string;

  // '1' ou true => TVA active
  tva: boolean | number;

  // '1' ou true => CSS active
  css: boolean | number;

  prix_de_vente: number | string;
  posologie: string;
  homologation: string;
  forme: string;
  famille: string;
  nature: string;
  classe_therapeutique: string;
  categorie: string;
  poids: number | string;
  longueur: number | string;
  largeur: number | string;
  rayon: string;
  hauteur: number | string;
  code_table: string;
  statut: string;
  code_fournisseur: string;
  rayon_id: string;
  cip_deux: string;
  fournisseurId: string;
  photo: string;
  prise_en_charge: number | string;
  montant_taxe:number | string;
}

/**
 * L'interface pour ta table "reservation_produits"
 */
export interface IReservationProduit {
  id: number;
  libelle: string;
  qte: string;             // ex: "1"
  prix_de_vente: string;   // ex: "3000"
  prix_achat: string;      // ex: "1562"
  cout_total: string;      // ex: "3000"
  cout_total_reel: string; // ex: "3000"
  produit: Produit;        // l’objet enfant
  reservation_id: string;  // ex: "56"
  prise_en_charge: string; // ex: "0"
  produit_id: string;      // ex: "116"
  total_ht: string;        // ex: "3000"
  total_tva: string;       // ex: "0"
  total_css: string;       // ex: "0"
  total_prise_en_charge: string; // ex: "0"
  total_garde: string;     // ex: "0"
  is_sold: number;         // ex: 0
  vente_id: number | null; // ex: null
  created_at: string;      // date en string
  updated_at: string;      // date en string
}

/**
 * Le résultat que tu renvoies : tous les totaux calculés.
 */
export interface ICalculResult {
  total: number;
  totalTVA: number;
  totalCSS: number;
  totalPrixVente: number;
  totalHorsTaxe: number;
  priseEnCharge: number;
  totalGarde: number;
  totalClient: number;
  totalPriseEnCharge: number;
}

/**
 * Fusion de la logique "calculerProduit" et l'existant,
 * avec gestion de la garde (montant_taxe), priseEnCharge, remise éventuelle,
 * ainsi que TVA et CSS en fonction du store (ou valeurs par défaut).
 *
 * @param reservationOrProd - L'objet de type IReservationProduit (incluant 'produit')
 *                            OU un objet produit brut (dans lequel 'tva' est au 1er niveau)
 * @param qte               - La quantité
 * @param garde             - L'objet garde (ou null si pas de garde)
 * @param prise_en_charge   - Pourcentage (ex: "20" => 20%)
 * @param remise_percent    - (Optionnel) remise en pourcentage (ex: 10 => 10%)
 * @returns Un objet ICalculResult
 */
// [MODIF] On autorise soit un IReservationProduit, soit un Produit brut
export function calculerTotalProduits(
  reservationOrProd: IReservationProduit | Produit,
  qte: number,
  garde: Garde | null,
  prise_en_charge: number | string,
  remise_percent?: number
): ICalculResult {

  // [MODIF] Identifier si on a un objet 'reservation_produits' ou un 'produit' brut
  let prodBrut: Produit;        // L'objet "produit" final
  let prixDeVenteStr: string;   // La chaîne représentant le prix de vente

  // Vérifions si reservationOrProd a la clé 'produit'
  if ('produit' in reservationOrProd && reservationOrProd.produit) {
    // => C'est un IReservationProduit
    prodBrut = reservationOrProd.produit;
    prixDeVenteStr = reservationOrProd.prix_de_vente; 
  } else {
    // => C'est un produit brut (depuis le stock)
    prodBrut = reservationOrProd as Produit;
    // On récupère la clé 'prix_de_vente' directement
    prixDeVenteStr = prodBrut.prix_de_vente as string;
  }

  // 1) Convertir les valeurs de base
  const prixDeVente = parseFloat(prixDeVenteStr) || 0;
  const priseEnCharge = parseFloat(prise_en_charge as string) || 0;

  // 2) Récupérer la taxe de garde si "garde" n'est pas null
  let montantTaxe = 0;
  if (garde && garde.montant_taxe !== undefined) {
    montantTaxe = parseFloat(garde.montant_taxe as string) || 0;
  }

  // 3) Calcul du pourcentage de prise en charge
  const percentPriseEnCharge = priseEnCharge > 0 ? priseEnCharge / 100 : 0;

  // 4) Vérifier les propriétés tva et css
  // [MODIF] Désormais on lit directement dans prodBrut
  const tvaVal = prodBrut.tva; 
  const cssVal = prodBrut.css;
  // '1' ou true => tvaActive
  const tvaActive = tvaVal === true || tvaVal === 1;
  // '1' ou true => cssActive
  const cssActive = cssVal === true || cssVal === 1;

  // Valeurs par défaut pour la tva et la css, au cas où store.getters.tva/css sont undefined
  const defaultTvaRate = 0.18; 
  const defaultCssRate = 0.01;

  // On essaie de prendre dans le store, sinon on utilise nos valeurs par défaut
  const storeTva = store?.getters?.tva;
  const storeCss = store?.getters?.css;

  const tvaRate = (typeof storeTva === 'number' && storeTva > 0) 
    ? storeTva 
    : defaultTvaRate;

  const cssRate = (typeof storeCss === 'number' && storeCss >= 0) 
    ? storeCss 
    : defaultCssRate;

  // 6) Calcul du total garde
  const totalGarde = montantTaxe * qte;

  // 7) totalPrixVente = prixDeVente * qte + garde
  const totalPrixVente = prixDeVente * qte + totalGarde;

  // 8) Calcul des taxes (TVA et CSS) seulement si le produit est marqué comme tva/cssActive
  const totalTVA = tvaActive ? totalPrixVente * tvaRate : 0;
  const totalCSS = cssActive ? totalPrixVente * cssRate : 0;

  // 9) Calcul du total hors taxe
  const totalHorsTaxe = totalPrixVente - totalTVA - totalCSS - totalGarde;

  // 10) Calcul du total général
  let total = totalHorsTaxe + totalTVA + totalCSS + totalGarde;

  // 11) Appliquer la remise si définie
  if (remise_percent && remise_percent > 0) {
    const totalRemise = total * (remise_percent / 100);
    total -= totalRemise;
  }

  // 12) Calcul de la prise en charge en montant
  const totalPriseEnCharge = total * percentPriseEnCharge;

  // 13) totalClient = total - prise en charge
  const totalClient = total - totalPriseEnCharge;

  // 14) Retourner le résultat
  return {
    total,
    totalTVA,
    totalCSS,
    totalPrixVente,
    totalHorsTaxe,
    priseEnCharge,
    totalGarde,
    totalClient,
    totalPriseEnCharge,
  };
}