<template>
  <div class="container">
    <h3 style="margin-bottom: 2.6rem;">Panier Moyen par Utilisateur</h3>
    <div class="card" @mouseover="showButton = true" @mouseleave="showButton = false">
      <div class="card-body">
        <canvas ref="panierMoyenChart"></canvas>
      </div>
      <button
        v-if="showButton"
        class="btn btn-primary export-btn"
        @click="openStatExportModal"
      >
        Exporter <i class="fas fa-file-export"></i>
      </button>
    </div>
  </div>
</template>

<script>
import Chart from "chart.js/auto";
import Statistiques from "./ExportStatistique.vue"; // Composant modal pour les statistiques

export default {
  name: "PanierMoyen",
  components: { Statistiques },
  props: {
    filters: {
      type: Object,
      required: true,
    },
    period: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      chartInstance: null,
      users: [],
      paniersMoyens: [],
      baseUrl: this.$store.state.api_epharma_ventes,
      showButton: false,
      statData: [],
      columns: ["Utilisateur", "Panier Moyen (FCFA)"],
      TitleStat : "Panier Moyen par Utilisateur",
    };
  },
  computed: {
    dataTable() {
      return {
        columns: this.columns,
        rows: this.statData,
        title: this.TitleStat,
      };
    },
  },
  watch: {
    filters: {
      deep: true,
      handler() {
        this.fetchPanierMoyen();
      },
    },
    period: {
      immediate: true,
      handler() {
        this.fetchPanierMoyen();
      },
    },
  },
  methods: {
    async fetchPanierMoyen() {
      try {
        let url = `${this.baseUrl}/api/statistiques/panier-moyen`;

        if (this.filters.debut && this.filters.fin) {
          url += `?period_from=${this.filters.debut}&period_to=${this.filters.fin}`;
        } else if (this.filters.week) {
          const [year, week] = this.filters.week.split("-W");
          const firstDayOfWeek = new Date(year, 0, 1 + (week - 1) * 7);
          const lastDayOfWeek = new Date(firstDayOfWeek);
          lastDayOfWeek.setDate(firstDayOfWeek.getDate() + 6);
          url += `?period_from=${firstDayOfWeek.toISOString().slice(0, 10)}&period_to=${lastDayOfWeek.toISOString().slice(0, 10)}`;
        } else if (this.filters.month) {
          const [year, month] = this.filters.month.split("-");
          const firstDayOfMonth = new Date(year, month - 1, 1);
          const lastDayOfMonth = new Date(year, month, 0);
          url += `?period_from=${firstDayOfMonth.toISOString().slice(0, 10)}&period_to=${lastDayOfMonth.toISOString().slice(0, 10)}`;
        }

        const response = await fetch(url);
        if (!response.ok) throw new Error("Erreur lors de la récupération des données.");

        const data = await response.json();

        this.users = data.map((item) => item.user);
        this.paniersMoyens = data.map((item) => item.panier_moyen);

        this.statData = data.map((item) => ({
          Utilisateur: item.user,
          "Panier Moyen (FCFA)": item.panier_moyen,
        }));

        this.$nextTick(() => this.renderChart());
      } catch (error) {
        console.error("Erreur lors de la récupération des données :", error);
      }
    },
    renderChart() {
      const canvas = this.$refs.panierMoyenChart;

      if (!canvas) {
        console.error("Le canvas n'est pas encore monté.");
        return;
      }

      const ctx = canvas.getContext("2d");

      if (this.chartInstance) {
        this.chartInstance.destroy();
      }

      this.chartInstance = new Chart(ctx, {
        type: "bar",
        data: {
          labels: this.users,
          datasets: [
            {
              label: "Panier Moyen (FCFA)",
              data: this.paniersMoyens,
              backgroundColor: "rgba(54, 162, 235, 0.6)",
              borderColor: "rgba(54, 162, 235, 1)",
              borderWidth: 1,
            },
          ],
        },
        options: {
          responsive: true,
          plugins: {
            legend: { display: true },
          },
          scales: {
            y: {
              beginAtZero: true,
            },
          },
        },
      });
    },
    openStatExportModal() {
      this.$dialog.open(Statistiques, {
        props: {
          header: "Statistiques - Panier Moyen par Utilisateur",
          style: {
            width: "70vw",
          },
          modal: true,
        },
        data: this.dataTable,
      });
    },
  },
  mounted() {
    this.fetchPanierMoyen();
  },
};
</script>

<style scoped>
.container {
  max-width: 800px;
  margin: 0 auto;
}

.export-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}
.card:hover .export-btn {
  opacity: 1;
}

.export-btn i {
  margin-left: 5px;
}
</style>
